import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import React, {useCallback, useEffect, useRef, useState} from 'react';

const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.grey['300']}`;
  return {
    months: {
      borderBottom: border,
      display: 'flex',
    },

    month: {
      borderRight: border,
      display: 'flex',
      flexDirection: 'column',

      '& > span': {
        alignItems: 'center',
        borderBottom: border,
        display: 'flex',
        fontSize: '0.9em',
        justifyContent: 'center',
        padding: theme.spacing(1)
      },

      '&:last-child': {
        borderRight: 0
      }
    },

    weeks: {
      display: 'flex',
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },

    week: {
      alignItems: 'center',
      borderRight: border,
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      margin: 0,
      padding: 0,
      transition: '0.25s color ease-in-out',

      '&:last-child': {
        borderRight: 0
      },

      '&:hover': {
        color: theme.palette.primary.main
      }
    },

    current: {
      color: theme.palette.primary.main
    },

    now: {
      backgroundColor: theme.palette.grey['300']
    },
  };
});

const NavigateByYear = ({navClass, week, onSelectWeek}) => {
  const classes = useStyles();
  const [year, setYear] = useState(null);
  const [months, setMonths] = useState([]);
  const initialised = useRef(false);

  useEffect(() => {
    if (week && !initialised.current) {
      initialised.current = true;
      setYear(week.year());
    }
  }, [week]);

  useEffect(() => {
    if (year) {
      const thisWeek = moment().startOf('isoWeek');
      const compareDate = 'YYYY-MM-DD';
      const compareMonth = 'YYYY-MM';
      const list = [];
      // noinspection JSCheckFunctionSignatures
      const date = moment().year(year).startOf('year');
      for (let m = 0; m < 12; m++) {
        const month = date.clone().month(m).startOf('month');
        const weeks = [];
        let workingWeek = month.clone();
        if (month.clone().startOf('isoWeek').format(compareDate) !== workingWeek.format(compareDate)) {
          workingWeek = workingWeek.endOf('isoWeek').add(1, 'day');
        }
        while (true) {
          weeks.push({
            title: workingWeek.format('D'),
            now: workingWeek.format(compareDate) === thisWeek.format(compareDate),
            current: workingWeek.format(compareDate) === week.format(compareDate),
            start: workingWeek.clone()
          });
          workingWeek = workingWeek.add(1, 'week');
          if (workingWeek.format(compareMonth) !== month.format(compareMonth)) {
            break;
          }
        }
        list.push({
          title: month.format('MMMM'),
          weeks,
        });
      }

      setMonths(list);
    }
  }, [year, week]);

  const handleNext = useCallback(() => {
    setYear(year + 1);
  }, [year]);

  const handleBack = useCallback(() => {
    setYear(year - 1);
  }, [year]);

  return year ? (
    <>
      <div className={navClass}>
        <button onClick={handleBack}><ChevronLeftIcon/></button>
        <h2><span>{year}</span></h2>
        <button onClick={handleNext}><ChevronRightIcon/></button>
      </div>
      <div className={classes.months}>
        {months.map((month, monthIndex) => (
          <div key={monthIndex} className={classes.month} style={{flex: month.weeks.length}}>
            <span>{month.title}</span>
            <ul className={classes.weeks}>
              {month.weeks.map((w, weekIndex) => (
                <li
                  key={`${monthIndex}-${weekIndex}`}
                  className={[classes.week, w.current ? classes.current : '', w.now ? classes.now : ''].join(' ')}
                  onClick={() => onSelectWeek(w.start)}
                >
                  {w.title}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  ) : null;
};

export default NavigateByYear;

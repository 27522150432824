import ImageIcon from '@mui/icons-material/Image';
import {CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey['400']}`,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    margin: `0 0 ${theme.spacing(2)}`,
    padding: theme.spacing(1),
  },
  title: {
    margin: `0 0 ${theme.spacing(1)}`,
    padding: 0,
    textAlign: 'center',
  },
  fileHolder: {
    backgroundColor: theme.palette.background.paper,
    padding: '0 0 70%',
    position: 'relative',
    width: '100%',
  },
  file: {
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  dropzone: {
    border: `1px dashed ${theme.palette.primary.main}`,
    flex: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    textAlign: 'center',

    '& p': {
      margin: 0,
      padding: 0,
    },

    '&:focus': {
      outline: 0,
    }
  },
}));

const FileUploader = ({title, prompt, image: initialPath, onUpload, onUploaded}) => {
  const classes = useStyles();
  const [path, setPath] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => setPath(initialPath), [initialPath]);

  const {getRootProps, getInputProps} = useDropzone(/** @type {Partial} */{
    accept: 'image/*',
    onDrop: useCallback(acceptedFiles => {
      if (acceptedFiles.length > 0) {
        setLoading(true);
        onUpload(acceptedFiles[0])
          .then(path => {
            setPath(path);
            onUploaded(path);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    }, [onUpload, onUploaded])
  });

  let fileStyles = {};
  if (path) {
    fileStyles.backgroundImage = `url(${path})`;
  }
  let spinner = null;
  if (loading) {
    fileStyles.opacity = 0.4;
    spinner = <CircularProgress style={{
      bottom: 0,
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 2,
    }}/>;
  }
  let holding = null;
  if (!path) {
    holding = <ImageIcon
      color="primary"
      style={{
        bottom: 0,
        fontSize: 100,
        left: 0,
        margin: 'auto',
        opacity: 0.4,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1
      }}/>
  }

  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      <div className={classes.fileHolder}>
        <div className={classes.file} style={fileStyles}/>
        {spinner}
        {holding}
      </div>
      <div {...getRootProps({className: classes.dropzone, multiple: false})}>
        <input {...getInputProps()} />
        <p>{prompt}</p>
      </div>
    </div>
  );
};

export default FileUploader;

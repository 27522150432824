import {Panes, TextField} from '@management-ui/core';
import {UploadField} from '@management-ui/upload';
import React from 'react';

export default function Fields({file = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={file}
      prefix={prefix}
      panes={[
        {
          title: 'Upload Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name for the file'}}
            />,
            <UploadField
              name="file"
              prefix={prefix}
              label="Drop a file here or click to select one from your computer"
              rules={file.id ? {} : {required: 'Please select a file'}}
              types=".jpg,.png,.gif,.pdf,.doc,.docx,.csv,.xls,.xlsx"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Upload Details'}
    >{children}</Panes>
  );
}

import {reverse} from 'named-urls';
import {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

/**
 * A hook to manage a set of cached tabs.
 *
 * @function useTab
 *
 * @param {Array<ITab>} tabs An array of objects to configure the available tabs.
 * @param {string} route The main route for the screen containing the tab set.
 *
 * @returns {{tab: string, handleTab: function}} `{tab: string, handleTab: function}`
 *
 * `tab` - The currently selected tab
 *
 * `handleTab` - A function to change the currently selected tab.
 *
 * @example
 * const {tab, handleTab} = useTab([
 *  {
 *    id: 'tab1',
 *    slug: 'tab-1',
 *    title: 'Tab 1',
 *    icon: <RecentActorsIcon/>,
 *    render: (props) => <Tab1Component {...props}/>
 *  }
 * ], '/path/to/route');
 *
 */
export function useTab(tabs, route) {
  const [tab, setTab] = useState(tabs[0].id);
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    const selected = `${params?.tab ?? ''}`;
    const matched = tabs.find(t => t.slug === selected);
    if (matched) {
      setTab(matched.id);
    } else {
      history.push(reverse(`${route}`, {tab: tabs[0].slug}));
    }
  }, [history, tabs, route, params]);

  const handleTab = useCallback((tab) => {
    let slug;
    if (tab) {
      slug = tab.slug;
    }
    history.push(reverse(`${route}`, slug ? {tab: slug} : {}));
  }, [history, route]);

  return {tab, handleTab};
}

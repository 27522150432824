import * as React from 'react';
import {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import SettingsForm from '../../forms/SettingsForm';
import {Settings as UISettings} from '@management-ui/core';

const Settings = () => {
  const services = useContext(ServiceContext);
  return (
    <UISettings
      getForm={useCallback((ref, settings, handleSaved, handleError) => (
        <SettingsForm ref={ref} type="settings" settings={settings} onSaved={handleSaved} onError={handleError}/>
      ), [])}
      loadSettings={useCallback(() => {
        return services.settings.getSettings();
      }, [services])}
    />
  );
};

export default Settings;

import {Close} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback, useRef, useState} from 'react';
import {ConfirmationDialog} from '../index';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    '& > span': {
      flex: 1,
    }
  },

  cancel: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    }
  },
  save: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    }
  },
}));

/**
 * A dialog to display an input form dialog with standard buttons
 *
 * @module FormDialog
 *
 * @param {string} title The title of the dialog
 * @param {boolean} open A toggle to determine if the dialog is showing
 * @param {function} onClose A function to be executed when the dialog is closed
 * @param {boolean} closeOnEscape A toggle to determine if the dialog should close when the background is clicked
 * @param {function} render A function which returns the form component
 * @param {boolean} fullScreen Set the dialog to full screen
 * @param {string} maxWidth The maximum width for the dialog
 * @param {number} minHeight The minimum height for the dialog
 * @param {IFormDialogLabels} buttons An object providing custom labels for the save and cancel buttons
 * @param {IFormDialogButton[]} additionalButtons An array of additional buttons to show in with the default actions
 * @param {string} headerCloseTitle Title for the header close dialog
 * @param {string} headerCloseMessage Message for the header close dialog
 *
 * @example
 * <FormDialog
 *   title="Edit User"
 *   open={true}
 *   onClose={() => console.log('Closed')}
 *   render={(props) => <UserForm {...props} />}
 *   minHeight={400}
 *   buttons={{save: 'Save User', cancel: 'Cancel Edit'}}
 *   additionalButtons={[{label: 'Delete', colour: 'primary', onClick: () => console.log('Delete')}]}
 * />
 *
 */
const FormDialog = (
  {
    title,
    open = false,
    onClose,
    closeOnEscape = true,
    render,
    fullScreen = false,
    maxWidth = 'xl',
    minHeight,
    buttons,
    additionalButtons = [],
    headerCloseTitle,
    headerCloseMessage
  }
) => {
  const classes = useStyles();
  const formRef = useRef(null);
  const [showClose, setShowClose] = useState(false);

  const handleClose = useCallback((confirmed) => {
    if (confirmed) {
      onClose();
    }
    setShowClose(false);
  }, [onClose]);

  return (
    <>
      <Dialog
        open={open}
        onClose={closeOnEscape ? () => onClose() : () => null}
        fullWidth={true}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
      >
        <DialogTitle className={classes.header} id="form-dialog-title">
          <span>{title}</span>
          {headerCloseTitle ? <IconButton onClick={() => setShowClose(true)}><Close/></IconButton> : null}
        </DialogTitle>
        <DialogContent style={minHeight ? {minHeight} : null}>
          {render({ref: formRef})}
        </DialogContent>
        <DialogActions>
          {additionalButtons.map(({label, onClick, colour}, index) => (
            <Button key={index} onClick={() => onClick()} color={colour ?? 'primary'}>
              {label}
            </Button>
          ))}
          {buttons == null || buttons.cancel != null ? (
            <Button className={classes.cancel} onClick={() => onClose()} color="primary">
              {buttons?.cancel ?? 'Cancel'}
            </Button>
          ) : null}
          {buttons == null || buttons.save != null ? (
            <Button className={classes.save} onClick={() => formRef.current.save()} color="primary">
              {buttons?.save ?? 'Save'}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      {headerCloseTitle ? (
        <ConfirmationDialog
          title={headerCloseTitle}
          message={headerCloseMessage ?? ''}
          open={showClose}
          onClose={handleClose}
        />
      ) : null}
    </>
  );
};

FormDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  render: PropTypes.func,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.number,
  buttons: PropTypes.object,
  additionalButtons: PropTypes.array,
  headerCloseTitle: PropTypes.string,
  headerCloseMessage: PropTypes.string
};

export default FormDialog;

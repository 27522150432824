import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {Paper, Tab} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

const useStyles = makeStyles(() => ({
  tabs: {
    '& .MuiTabPanel-root': {
      padding: 0
    }
  },
  container: {
    display: 'none',
    flex: '0 0 100%',
    width: '100%'
  },
  open: {
    display: 'block',
  }
}));

/**
 * A component to display a tab list and not re-render the contents.
 *
 * @module CachedTabs
 *
 * @param {ITab[]} tabs An array of tabs
 * @param {string} tab The id of the currently selected tab
 * @param {function} onChange A function to call when the selected tab is changed.
 *
 * @example
 * <CachedTabs
 *   tabs={[
 *     {id: 'tabA', slug: 'tab-a', title: 'Tab A', icon: <RecentActorsIcon/>, render: () => <p>Tab A</p>},
 *     {id: 'tabB', slug: 'tab-b', title: 'Tab B', icon: <RecentActorsIcon/>, render: () => <p>Tab B</p>}
 *   ]}
 *   tab="tabA"
 *   onChange={(selected) => console.log(selected)}
 * />
 *
 */
const CachedTabs = ({tabs, tab, onChange}) => {
  const classes = useStyles();

  const [availableTabs, setAvailableTabs] = useState({});
  useEffect(() => {
    if (!availableTabs[tab]) {
      const selected = tabs.find(t => t.id === tab);
      const update = {...availableTabs};
      update[tab] = selected.render();
      setAvailableTabs(update);
    }
  }, [tabs, tab, availableTabs]);

  return (
    <Paper className={classes.tabs}>
      <TabContext value={tab}>
        <TabList
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          onChange={(_e, selected) => onChange(tabs.find(t => t.id === selected))}>
          {tabs.map(t => <Tab key={t.id} label={t.title} icon={t.icon} value={t.id}/>)}
        </TabList>
      </TabContext>
      {tabs.map(t => (
        <div key={t.id} className={[classes.container, tab === t.id ? classes.open : ''].join(' ')}>
          {availableTabs[t.id]}
        </div>
      ))}
    </Paper>
  );
};

CachedTabs.propTypes = {
  tabs: PropTypes.array,
  tab: PropTypes.string,
  onChange: PropTypes.func
};

export default CachedTabs;

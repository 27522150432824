import {Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    alignItems: 'center',
    display: 'flex',
  },
  body: {
    border: `1px solid ${theme.palette.grey['300']}`,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    margin: `${theme.spacing(2)} 0`,
    padding: `${theme.spacing(2)} 0 0`,
  }
}));

const DetailDialog = ({title, maxWidth = 'sm', actions = [], open, onClose, children}) => {
  const classes = useStyles();
  const theme = useTheme();

  return children ? (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        fullWidth={true}
        maxWidth={maxWidth}
        PaperProps={{style: {backgroundColor: theme.palette.background.default}}}
      >
        <DialogTitle classes={{root: classes.title}}>{title}</DialogTitle>
        <DialogContent classes={{root: classes.body}}>
          {children}
        </DialogContent>
        <DialogActions>
          {actions.map(({label, onClick, colour = 'secondary', variant = 'contained'}, index) => (
            <Button key={index} onClick={onClick} color={colour} variant={variant}>{label}</Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  ) : null;
}

export default DetailDialog;

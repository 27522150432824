import {Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';

const EditFields = ({question = {}, header = false, prefix = ''}) => {

  const handleReset = useCallback((subject) => {
    subject.type = 'standard'
    if (subject.id && subject.is_required_file) {
      subject.type = 'requiredFile'
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={question}
      prefix={prefix}
      onReset={handleReset}
      panes={!header ? [
        {
          title: 'Question Body',
          fields: [
            <Select
              name="type"
              prefix={prefix}
              label="Type"
              options={[
                {title: 'Standard', value: 'standard'},
                {title: 'Required File', value: 'requiredFile'},
              ]}
            />,
            <TextField
              name="body"
              prefix={prefix}
              label="Body"
              rules={{required: 'Please enter the body of this question'}}
              fieldProps={{multiline: true, rows: 6}}
            />,
            <TextField
              name="link"
              prefix={prefix}
              label="Link"
            />,
          ]
        }, {
          title: 'Criteria',
          fields: [
            <TextField
              name="not_met"
              prefix={prefix}
              label="Not Met"
              fieldProps={{multiline: true, rows: 3}}
            />,
            <TextField
              name="partly_met"
              prefix={prefix}
              label="Partly Met"
              fieldProps={{multiline: true, rows: 3}}
            />,
            <TextField
              name="fully_met"
              prefix={prefix}
              label="Fully Met"
              fieldProps={{multiline: true, rows: 3}}
            />,
          ]
        },
      ] : [
        {
          title: 'Header',
          fields: [
            <TextField
              name="body"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title for the header'}}
            />,
          ]
        }
      ]}
      title={''}/>
  );
};

export default EditFields;

import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  yes: {
    color: theme.palette.success.main
  },

  no: {
    color: theme.palette.error.main
  }
}))

const YesNo = ({value}) => {
  const classes = useStyles();
  return <strong className={value ? classes.yes : classes.no}>{value ? 'Yes' : 'No'}</strong>
};

export default YesNo;


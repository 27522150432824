import {ColouredLabel} from '@management-ui/core';
import React from 'react';
import {useAuditStatuses} from '../hooks';

const AuditStatus = ({status}) => {
  const statuses = useAuditStatuses();
  const current = statuses.find(s => s.title === status);
  return current ? <ColouredLabel label={current.title} colour={current.colour}/> : null;
}

export default AuditStatus;

import {TextField as MatTextField} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {prefixWithSeparator} from './utilities';

/**
 * A text field for use within the `BaseForm` component.
 *
 * @module TextField
 *
 * @param {string} name The name of the field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {object} fieldProps Any additional props for the Material UI [TextField](https://material-ui.com/api/text-field/)
 *
 * @example
 * <TextField
 *   name="first_name"
 *   prefix={prefix}
 *   label="First Name"
 *   id="firstName"
 *   rules={{required: 'Please enter your first name'}}
 * />
 *
 */
const TextField = (
  {
    name,
    prefix = '',
    label,
    id = null,
    rules = {},
    fieldProps
  }
) => {
  const {control, formState: {errors}} = useFormContext();
  const prefixedName = `${prefixWithSeparator(prefix)}${name}`
  return (
    <Controller
      name={prefixedName}
      control={control}
      rules={rules}
      render={({field}) => (
        <MatTextField
          {...fieldProps}
          {...field}
          value={`${field.value ?? ''}`}
          fullWidth
          error={!!errors[prefixedName]}
          helperText={!!errors[prefixedName] ? errors[prefixedName].message : ''}
          variant="outlined"
          margin="normal"
          id={id ?? prefixedName}
          label={label}
          required={rules && !!rules.required}
        />
      )}
    />
  )
};

TextField.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object,
  fieldProps: PropTypes.object
};

export default TextField;

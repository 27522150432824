import {FormDialog} from '@management-ui/core';
import {Box, Button} from '@mui/material';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import QuestionForm from '../../forms/QuestionForm';

const ManageQuestion = ({open, onClose, question, onSaved}) => {
  const services = useContext(ServiceContext);
  const [mode, setMode] = useState('');

  useEffect(() => {
    if (open) {
      if (question?.id) {
        setMode(question.is_header ? 'header' : 'question');
      } else {
        setMode('');
      }
    }
  }, [open, question]);

  const handleClose = useCallback(() => {
    if (!question?.id && mode) {
      setMode('');
    } else {
      onClose();
    }
  }, [question, mode, onClose]);

  const handleSave = useCallback((data) => services.question.saveQuestion(data), [services]);

  return (
    <FormDialog
      title={`${question?.id ? 'Edit' : 'Add New'} ${mode === 'header' ? 'Header' : mode === 'question' ? 'Question' : 'Header / Question'}`}
      open={open}
      maxWidth="md"
      onClose={handleClose}
      render={useMemo(() => (props) => (
        mode ? (
          <QuestionForm
            {...props}
            question={question ?? {}}
            header={mode === 'header'}
            onSave={handleSave}
            onSaved={onSaved}
          />
        ) : (
          <Box display="flex" flexWrap="wrap" width="100%" paddingY={6}>
            <Box flex="0 0 100%" width="100%" paddingBottom={4}>
              <Box textAlign="center" fontSize="1.4em">
                Would you like to create a new question or add a header?
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" flex={1} paddingRight={2}>
              <Button variant="contained" color="primary" onClick={() => setMode('header')}>Add a Header</Button>
            </Box>
            <Box display="flex" justifyContent="center" flex={1} paddingLeft={2}>
              <Button variant="contained" color="secondary" onClick={() => setMode('question')}>Add a Question</Button>
            </Box>
          </Box>
        )
      ), [mode, question, handleSave, onSaved])}
    />
  );
};

export default ManageQuestion;

import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const AttributeForm = forwardRef(({attribute, singularName, serviceName, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const handleSave = useCallback(({name}) => (
    services.attribute[serviceName].saveAttribute({...(attribute?.id ? {id: attribute.id} : {}), name})
  ), [services, serviceName, attribute]);

  return (
    <BaseForm
      ref={formRef}
      entity={attribute}
      type="attribute"
      fieldsComponent={(props) => <Fields singularName={singularName} {...props}/>}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default AttributeForm;

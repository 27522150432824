import {useDialogs, useErrors} from '@management-ui/core';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import AuditDetail from './AuditDetail';
import Notes from './Notes';
import Overview from './Overview';
import SectionDetail from './SectionDetail';
import Switcher from './Switcher';
import Wrapper from './Wrapper';

const Audit = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({audit: 'Sorry the audit could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs([
    'completeAudit',
    'incompleteAudit',
    'approveAudit',
    'editAudit',
    'editAuditReviewDate',
    'copyAudit',
    'editUser',
    'deleteAudit'
  ]);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [audit, setAudit] = useState(null);
  const [tab, setTab] = useState('');
  const tabRef = useRef('');
  const [section, setSection] = useState(null);

  const tabs = useMemo(() => [
    {
      title: 'Overview',
      path: reverse(routes.audits.tab, {id: audit?.id ?? 0, tab: 'overview'})
    },
    {
      title: 'Ratings',
      path: reverse(routes.audits.section, {id: audit?.id ?? 0, tab: 'ratings', sectionID: section?.id ?? 0})
    },
    {
      title: 'Notes',
      path: reverse(routes.audits.tab, {id: audit?.id ?? 0, tab: 'notes'})
    }
  ], [audit, section]);

  const loadAudit = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.audit.getAudit(id).then(retrieved => {
        setAudit(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('audit', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    const id = match.params?.id ?? null;
    const sectionID = match.params?.sectionID ?? null;
    if (match.params?.tab) {
      tabRef.current = match.params?.tab;
      setTab(match.params?.tab);
      if (id) {
        loadAudit(id);
      }
    } else {
      let route = routes.audits.tab;
      let routeParams = {id, tab: 'overview'};
      if (id && sectionID) {
        route = routes.audits.section;
        routeParams = {...routeParams, sectionID};
      }
      history.push(reverse(route, routeParams));
    }
  }, [history, match, audit, loadAudit]);

  useEffect(() => {
    const tab = tabRef.current;
    if (tab && audit) {
      if (tab === 'ratings') {
        if (match.params?.sectionID) {
          const found = audit.sections.find(s => `${s.id}` === match.params.sectionID);
          if (found) {
            setSection(found);
          } else {
            history.push(reverse(routes.audits.tab, {id: audit.id, tab}));
          }
        } else if (audit.sections.length > 0) {
          history.push(reverse(routes.audits.section, {
            id: audit.id,
            tab,
            sectionID: audit.sections[0].id
          }));
        } else {
          setSection(null);
        }
      } else {
        setSection(null);
      }
    }
  }, [history, match, audit]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (audit) {
      header = `${audit.school.name} - ${audit.title}`;
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [audit, section]);

  const handleSaved = useCallback((updated) => {
    if (updated) {
      setAudit(updated);
    } else if (audit?.id) {
      loadAudit(audit.id);
    }
  }, [setAudit, loadAudit, audit]);

  return (
    <Wrapper title={title} loading={loading} crumbs={crumbs} errors={errors}>
      {audit?.id ? (
        <>
          <AuditDetail
            audit={audit}
            loading={loading}
            onLoading={setLoading}
            openDialogs={openDialogs}
            toggleDialog={toggleDialog}
            onSaved={handleSaved}
            onAuditUpdated={setAudit}
          />
          <Switcher tabs={tabs}/>
          {tab === 'overview' ? (
            <Overview audit={audit}/>
          ) : tab === 'ratings' ? (
            section ? (
              <SectionDetail
                audit={audit}
                section={section}
                openDialogs={openDialogs}
                toggleDialog={toggleDialog}
                loading={loading}
                onLoading={setLoading}
                onSaved={handleSaved}
                onAuditUpdated={setAudit}
              />
            ) : null
          ) : tab === 'notes' ? (
            <Notes audit={audit} onAuditUpdated={setAudit}/>
          ) : null}
        </>
      ) : null}
    </Wrapper>
  );
};

export default Audit;

import * as qs from 'qs';
import {API} from './API';
import {ServiceProvider} from './ServiceProvider';

export class AuditService {

  static LIST_KEY = 'audits';
  static QUEUE_KEY = 'updateQueue'

  async getTableAudits(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    const {general: {online}} = API.store.getState();
    if (!online && additional.status === 'ongoing' && `${page}` === '1') {
      return new Promise((resolve, reject) => {
        const cached = ServiceProvider.getServices().cache.read(AuditService.LIST_KEY);
        if (cached) {
          resolve(cached);
        } else {
          reject();
        }
      });
    }
    return this.getAudits(query, page, length).then(response => {
      ServiceProvider.getServices().cache.write(AuditService.LIST_KEY, response);
      return response;
    }).catch(error => error);
  }

  async getAudits(query, page, length) {
    return (await API.getConnection())
      .get('audits', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getSummary() {
    return (await API.getConnection())
      .get('audits/summary')
      .then(response => response.data.summary)
      .catch(error => API.handleError(error));
  }

  retrieveFromCache(id) {
    const cached = ServiceProvider.getServices().cache.read(AuditService.LIST_KEY);
    if (cached) {
      return cached.data.find(a => `${a.id}` === `${id}`);
    }
  }

  updateCache(updated) {
    const cached = ServiceProvider.getServices().cache.read(AuditService.LIST_KEY);
    if (cached) {
      const index = cached.data.findIndex(a => `${a.id}` === `${updated.id}`);
      if (index >= 0) {
        cached.data[index] = updated;
        ServiceProvider.getServices().cache.write(AuditService.LIST_KEY, cached);
      }
    }
  }

  queueUpdate(method, params) {
    const cache = ServiceProvider.getServices().cache;
    const queue = cache.read(AuditService.QUEUE_KEY, []);
    queue.push({method, params});
    cache.write(AuditService.QUEUE_KEY, queue);
  }

  async executeUpdates() {
    const cache = ServiceProvider.getServices().cache;
    return new Promise(async (resolve) => {
      const queue = cache.read(AuditService.QUEUE_KEY, []);
      for (let {method, params} of queue) {
        const updated = await this[method](...params);
        if (updated) {
          this.updateCache(updated);
        }
      }
      cache.write(AuditService.QUEUE_KEY, []);
      resolve();
    });
  }

  async getAudit(id) {
    const {general: {online}} = API.store.getState();
    if (!online) {
      return new Promise((resolve, reject) => {
        const audit = this.retrieveFromCache(id);
        if (audit) {
          resolve(audit);
        }
        reject();
      });
    }
    return (await API.getConnection())
      .get(`audits/${id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async downloadReport(audit) {
    return (await API.getConnection())
      .get(`audits/${audit.id}/report`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['x-filename']);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async downloadCriteria(audit) {
    return (await API.getConnection())
      .get(`audits/${audit.id}/criteria`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['x-filename']);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async saveAudit(data) {
    const {general: {online}} = API.store.getState();
    if (!online) {
      this.queueUpdate('saveAudit', [data]);
      const cached = this.retrieveFromCache(data.id);
      const updated = {...cached, ...data};
      this.updateCache(updated);
      return new Promise(resolve => resolve(updated));
    }
    let method = 'post';
    let url = 'audits';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async updateLead(audit, lead) {
    return (await API.getConnection())
      .put(`audits/${audit.id}/lead`, {lead})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async completeAudit(audit) {
    return (await API.getConnection())
      .put(`audits/${audit.id}/complete`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async incompleteAudit(audit) {
    return (await API.getConnection())
      .put(`audits/${audit.id}/incomplete`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async approveAudit(audit) {
    return (await API.getConnection())
      .put(`audits/${audit.id}/approve`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async reindexAudit(audit) {
    return (await API.getConnection())
      .put(`audits/${audit.id}/reindex`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async copyAudit(audit, fromID, questionIDs) {
    return (await API.getConnection())
      .post(`audits/${audit.id}/copy`, {from_id: fromID, questions: questionIDs})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteAudit(audit) {
    return (await API.getConnection())
      .delete(`audits/${audit.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async restoreAudit(audit) {
    return (await API.getConnection())
      .put(`audits/${audit.id}/restore`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveQuestion(audit, section, question, action = false) {
    const {general: {online}} = API.store.getState();
    if (!online) {
      return new Promise(async resolve => {
        this.queueUpdate('saveQuestion', [audit, section, question, action]);
        const cached = {...this.retrieveFromCache(audit.id)};
        const cachedSection = cached.sections.find(s => `${s.id}` === `${section.id}`);
        if (cachedSection) {
          const index = cachedSection.questions.findIndex(q => `${q.id}` === `${question.id}`);
          if (index >= 0) {
            cachedSection.questions[index] = {...cachedSection.questions[index], ...question};
          }
        }
        const progress = {no_rating: 0, not_met: 0, partly_met: 0, fully_met: 0};
        cached.sections.forEach(s => {
          s.questions.forEach(q => {
            switch (`${q.rating}`) {
              case '1':
                progress.not_met += 1;
                break;
              case '2':
                progress.partly_met += 1;
                break;
              case '3':
                progress.fully_met += 1;
                break;
              default:
                progress.no_rating += 1;
                break;
            }
          });
        });
        this.updateCache({...cached, progress});
        resolve(cached);
      });
    }
    const {id, ...data} = question;
    return (await API.getConnection())
      .put(`audits/${audit.id}/sections/${section.id}/questions/${id}${action ? '/action' : ''}`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteSection(audit, section) {
    return (await API.getConnection())
      .delete(`audits/${audit.id}/sections/${section.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteQuestion(audit, section, question) {
    return (await API.getConnection())
      .delete(`audits/${audit.id}/sections/${section.id}/questions/${question.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveFile(audit, section, question, name, file) {
    const body = new FormData();
    body.append('name', name);
    if (file) {
      body.append('file', file);
    }
    return (await API.getConnection())
      .post(`audits/${audit.id}/sections/${section.id}/questions/${question.id}`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async downloadFile(audit, file) {
    return (await API.getConnection())
      .get(`audits/${audit.id}/files/${file.id}`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['x-filename']);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async deleteFile(audit, file) {
    return (await API.getConnection())
      .delete(`audits/${audit.id}/files/${file.id}`, {})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveNote(audit, comment) {
    const {general: {online}} = API.store.getState();
    if (!online) {
      return new Promise(async resolve => {
        this.queueUpdate('saveNote', [audit, comment]);
        const cached = this.retrieveFromCache(audit.id);
        const updated = {
          ...cached, notes: [
            {
              id: 0,
              comment,
              user: await ServiceProvider.getServices().auth.me(),
              created_at: (new Date()).toISOString()
            },
            ...cached.notes
          ]
        };
        this.updateCache(updated);
        resolve(updated);
      });
    }
    return (await API.getConnection())
      .post(`audits/${audit.id}/notes`, {comment})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}

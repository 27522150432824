import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const AuditForm = forwardRef(({audit, includeAssignment = true, includeUser = false, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const handleSave = useCallback((update) => {
    let data = audit && audit.id ? {id: audit.id} : {type_id: update.type ?? null};
    if (includeAssignment) {
      const {school_id, user_id, date} = update;
      data = {...data, school_id, user_id, date};
    }
    if (includeUser) {
      const {
        name,
        job_title,
        head_teacher,
        chair_of_governors,
        safeguarding_governor,
        ceo,
        chair_of_trustees,
        safeguarding_trustee,
        signed_off
      } = update;
      data = {
        ...data,
        name,
        job_title,
        head_teacher,
        chair_of_governors,
        safeguarding_governor,
        ceo,
        chair_of_trustees,
        safeguarding_trustee,
        signed_off
      };
    }
    return services.audit.saveAudit(data);
  }, [services, audit, includeAssignment, includeUser]);

  return (
    <BaseForm
      ref={formRef}
      entity={audit}
      type="audit"
      fieldsComponent={(props) => <Fields includeAssignment={includeAssignment} includeUser={includeUser} {...props}/>}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default AuditForm;

import React from 'react';
import DetailDialog from '../../../../components/DetailDialog';
import Questions from './Questions';

const QuestionsDialog = ({title, questions, open, onClose}) => (
  <DetailDialog
    title={title}
    maxWidth="md"
    open={open}
    onClose={onClose}
    actions={[
      {label: 'Close', colour: 'primary', onClick: () => onClose()}
    ]}
  >
    <Questions questions={questions}/>
  </DetailDialog>
);

export default QuestionsDialog;

import AutorenewIcon from '@mui/icons-material/Autorenew';
import {Fab} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  floating: {
    bottom: theme.spacing(2),
    position: 'fixed',
    right: theme.spacing(2),
    zIndex: 500,

    '& > *': {
      marginLeft: theme.spacing(2)
    },

    '& :first-child': {
      marginLeft: 0
    },
  },
  floatingButtonIcon: {
    marginRight: theme.spacing(1),
  },
  '@keyframes spin': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'}
  },
  spinning: {
    animationName: '$spin',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  }
}));

/**
 * A component to display floating buttons, designed to work with the `useFlowingButtons` hook.
 *
 * @module FloatingButtons
 *
 * @param {IFloatingButton[]} buttons An array which contains buttons with their configuration
 * @param {function} onClick A function to be executed when a button is clicked
 *
 * @example
 * <FloatingButtons
 *   buttons={[{name: 'buttonA', label: 'Button A', icon: EditIcon, colour: 'secondary', spinning: false}]}
 *   onClick={(name) => console.log(name)}
 * />
 *
 */
const FloatingButtons = ({buttons, onClick}) => {
  const classes = useStyles();
  return buttons && buttons.length > 0 ? (
    <div className={classes.floating}>
      {buttons.map(({name, label, icon: Icon, colour, spinning}, index) => (
        <Fab key={index} color={colour ?? 'secondary'} variant="extended" onClick={() => onClick(name)}>
          {spinning ? (
            <AutorenewIcon className={`${classes.floatingButtonIcon} ${classes.spinning}`}/>
          ) : (
            <Icon className={classes.floatingButtonIcon}/>
          )}
          {label}
        </Fab>
      ))}
    </div>
  ) : null;
};

FloatingButtons.propTypes = {
  buttons: PropTypes.array,
  onClick: PropTypes.func
};

export default FloatingButtons;

import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({attribute = {}, singularName, prefix = ''}) => {
  return (
    <Panes
      entity={attribute}
      prefix={prefix}
      panes={[
        {
          title: `${singularName} Details`,
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: `Please enter a name for this ${singularName.toLowerCase()}`}}
            />
          ]
        }, {
          title: '',
          fields: []
        }
      ]}
      title={''}
    />
  );
}

export default Fields;

import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import {FormatListBulleted} from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import * as React from 'react';
import routes from '../../../../routes';
import Attributes from './Attributes';
import Settings from './Settings';

const TABS = [
  {
    id: 'settings',
    slug: 'settings',
    title: 'Settings',
    icon: <SettingsIcon/>,
    render: (props) => <Settings {...props} />
  }, {
    id: 'attributes',
    slug: 'attributes',
    title: 'Attributes',
    icon: <FormatListBulleted/>,
    render: (props) => <Attributes {...props}/>
  }
];

const Management = () => {
  const {tab, handleTab} = useTab(TABS, routes.management);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Management'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
};

export default Management;

import {makeStyles} from '@mui/styles';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {ServiceContext} from '../../components/Services';

const useStyles = makeStyles((theme) => {
  const pseudo = {
    borderRadius: 8,
    content: '""',
    height: 16,
    marginRight: theme.spacing(),
    width: 16,
  };
  return ({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'flex-end',
    },

    indicator: {
      alignItems: 'center',
      color: theme.palette.grey['600'],
      display: 'flex',
      flex: 0,
      fontWeight: '600',
      justifyContent: 'center',
      padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)}`,
      width: '100%',
    },

    online: {
      '&:before': {
        ...pseudo,
        backgroundColor: theme.palette.success.main
      }
    },

    offline: {
      '&:before': {
        ...pseudo,
        backgroundColor: theme.palette.error.main
      }
    }
  })
});

const OnlineStatus = () => {
  const classes = useStyles();
  const {online} = useSelector(state => state['general']);
  const clearingCache = useRef(false);
  const services = useContext(ServiceContext);
  const [syncing, setSyncing] = useState(false);

  useEffect(() => {
    if (online && !clearingCache.current) {
      clearingCache.current = true;
      setSyncing(true);
      services.audit.executeUpdates()
        .then(() => {
          clearingCache.current = false;
          setSyncing(false);
        })
        .catch(() => {
          clearingCache.current = false;
          setSyncing(false);
        });
    }
  }, [services, online])

  return (
    <div className={classes.container}>
      <span className={classes.indicator}>&nbsp;{syncing ? 'Syncing Offline Work...' : ''}&nbsp;</span>
      <span className={[classes.indicator, online ? classes.online : classes.offline].join(' ')}>
        {online ? 'Online' : 'Offline'}
      </span>
    </div>
  )
};

export default OnlineStatus;

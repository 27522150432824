import * as React from 'react';
import {useState} from 'react';
import Navigation from '../../../../components/Navigation';
import Attribute from './Attribute';

const ATTRIBUTES = [
  {
    id: 'schoolTypes',
    menuTitle: 'School Types',
  },
  {
    id: 'ofstedRatings',
    menuTitle: 'Ofsted Ratings',
  },
];

const Attributes = () => {
  const [selected, setSelected] = useState('schoolTypes')

  return selected ? (
    <Navigation
      items={ATTRIBUTES}
      current={selected}
      onSelect={({id}) => setSelected(id)}
    >
      {selected === 'schoolTypes' ? (
        <Attribute
          singularName="School Type"
          pluralName="School Types"
          serviceName="schoolType"
        />
      ) : selected === 'ofstedRatings' ? (
        <Attribute
          singularName="Ofsted Rating"
          pluralName="Ofsted Ratings"
          serviceName="ofstedRating"
        />
      ) : null}
    </Navigation>
  ) : null;
};

export default Attributes;

export const SET_ONLINE = 'SET_ONLINE';

const general = (
  state = {
    online: true
  },
  action,
) => {
  switch (action.type) {
    case SET_ONLINE:
      return {
        ...state,
        online: action.online
      };
    default:
  }
  return state;
};

export default general;

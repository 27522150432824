import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {fullDateTime} from '../formatters';

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%',
  },

  action: {
    backgroundColor: theme.palette.background.default,
    borderRadius: `${theme.spacing(1)}`,
    margin: `${theme.spacing(1)}`,
    padding: `${theme.spacing(1)}`,

    '&:nth-child(odd)': {
      borderRight: `5px solid ${theme.palette.primary.main}`,
      marginLeft: '20%',
    },
    '&:nth-child(even)': {
      borderLeft: `5px solid ${theme.palette.primary.main}`,
      marginRight: '20%',
    },
  },

  title: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },

  actionVitals: {
    color: theme.palette.primary.main,
    display: 'flex',
    marginTop: theme.spacing(2),
  },

  actionWho: {
    flex: 0,
    fontWeight: 700,
    whiteSpace: 'nowrap'
  },

  actionWhen: {
    flex: 1,
    fontStyle: 'italic',
    textAlign: 'right'
  },
}));

/**
 * A component to display a list of action boxes.
 *
 * @module ActionList
 *
 * @param {IAction[]} actions An array of actions to display
 * @param {function} renderAction A function which returns a component for the associated action
 *
 * @example
 * <ActionList
 *   actions={[{title: 'Action', who: 'Person A', when: '2021-06-06 12:00:00'}]}
 *   renderAction={(action) => <p>{action.title}</p>}
 * />
 *
 */
const ActionList = ({actions, renderAction}) => {
  const classes = useStyles();
  return (
    <ul className={classes.actions}>
      {actions.map((action, index) => {
        return (
          <li key={index} className={classes.action}>
            <h3 className={classes.title}>{action.title}</h3>
            {renderAction(action)}
            <div className={classes.actionVitals}>
              {action.who ? <span className={classes.actionWho}>{`${action.who}`}</span> : null}
              {action.when ?
                <span className={classes.actionWhen}>{fullDateTime(action.when)}</span> : null}
            </div>
          </li>
        );
      })}
    </ul>
  )
};

ActionList.propTypes = {
  actions: PropTypes.array,
  renderAction: PropTypes.func
};

export default ActionList;

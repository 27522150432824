import {Checkbox, Panes, prefixWithSeparator, TextField} from '@management-ui/core';
import validator from 'email-validator';
import React, {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';

export default function Fields(
  {
    user = {},
    prefix = '',
    multiplePanes = true,
    children
  }
) {
  const {/** @var {function(string): string} */watch} = useFormContext();
  const password = watch(`${prefixWithSeparator(prefix)}password`);
  const confirm = watch(`${prefixWithSeparator(prefix)}confirmPassword`);

  const handleReset = useCallback((subject) => {
    if (subject.location) {
      subject.location_id = subject.location.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={user}
      onReset={handleReset}
      prefix={prefix}
      panes={[
        {
          title: 'Personal Details',
          fields: [
            <TextField
              name="first_name"
              prefix={prefix}
              label="First Name"
              rules={{required: 'Please enter a first name'}}
            />,
            <TextField
              name="last_name"
              prefix={prefix}
              label="Last Name"
              rules={{required: 'Please enter a last name'}}
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
              rules={{
                required: 'Please enter a email address',
                validate: value => {
                  if (value) {
                    return validator.validate(value) ? true : 'Please enter a valid email address';
                  }
                  return true;
                }
              }}
              autoComplete="email"
            />
          ]
        }, {
          title: 'Access Details',
          fields: [
            <TextField
              name="password"
              prefix={prefix}
              label="Password"
              rules={{
                ...(!user.id ? {required: 'Please enter a password'} : {}),
                validate: useCallback((value) => {
                  if (value) {
                    return value === confirm ? true : 'Please make sure the passwords match';
                  }
                  return true;
                }, [confirm])
              }}
              fieldProps={{
                type: 'password',
                autoComplete: 'password'
              }}
            />,
            <TextField
              name="confirmPassword"
              prefix={prefix}
              label="Confirm Password"
              rules={{
                validate: useCallback((value) => {
                  if (password) {
                    return value ? true : 'Please confirm the password';
                  }
                  return true;
                }, [password])
              }}
              fieldProps={{
                type: 'password',
                autoComplete: 'password'
              }}
            />,
            <Checkbox
              name="is_active"
              prefix={prefix}
              label="Active User?"
            />,
            <Checkbox
              name="is_developer"
              prefix={prefix}
              label="Is a Developer? (will be excluded from auditor lists)"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'User Details'}
    >{children}</Panes>
  );
}

import {API} from '../API';

export class SectionService {

  async reorderSections(type, sections) {
    return (await API.getConnection())
      .put(`audit-types/${type.id}/sections`, {sections})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveSection(type, data) {
    let method = 'post';
    let url = `audit-types/${type.id}/sections`;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteSection(type, section) {
    return (await API.getConnection())
      .delete(`audit-types/${type.id}/sections/${section.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}

import {Box, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  label: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 600,
    margin: 0,
    padding: 0,
  },

  items: {
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    padding: 0
  },

  item: {
    alignItems: 'center',
    display: 'flex',
    margin: `0 0 0 ${theme.spacing(2)}`,
    padding: 0,

    '& > span': {
      height: theme.spacing(2),
      marginRight: theme.spacing(1),
      width: theme.spacing(2),
    }
  }
}));

/**
 * Display a colour-coded key
 *
 * @module Key
 *
 * @param {IKeyItem[]} items The items to display in the key
 *
 * @example
 * <Key items={[{title: 'Red', colour: '#ff0000'}, {title: 'Blue', colour: '#0000ff'}]}/>
 *
 */
const Key = ({items}) => {
  const classes = useStyles();
  return (
    <Box marginY={2}>
      <Grid container={true} alignItems="center" justifyContent="center">
        <p className={classes.label}>Key:</p>
        <ul className={classes.items}>
          {items.map((item, index) => (
            <li key={index} className={classes.item}><span style={{backgroundColor: item.colour}}/>{item.title}</li>
          ))}
        </ul>
      </Grid>
    </Box>
  );
}

Key.propTypes = {
  items: PropTypes.array
};

export default Key;

import {ConfirmationDialog, dateTime, DetailColumns, DetailPane, FormDialog} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import AuditTypeSectionForm from '../../forms/AuditTypeSectionForm';
import Navigation from './Navigation';
import Questions from './Questions';

const SectionDetail = ({type, section, onLoading, openDialogs, toggleDialog, onSaved, onTypeUpdated}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('deleteSection', false);
    if (confirmed) {
      onLoading(true);
      services.auditType.section.deleteSection(type, section).then(updated => {
        onLoading(false);
        onSaved(updated);
      }).catch(() => onLoading(false));
    }
  }, [services, type, section, onLoading, onSaved, toggleDialog]);

  const handleBack = useCallback(
    () => history.push(reverse(routes.auditTypes.detail, {id: type.id})),
    [history, type]
  );

  const handleSelect = useCallback((selected) => history.push(reverse(routes.auditTypes.section, {
    id: type.id,
    sectionID: selected.id
  })), [history, type]);

  return type ? (
    <Navigation
      items={type.sections.map(s => ({...s, menuTitle: `${s.index}. ${s.title}`}))}
      current={section.id}
      backLabel="Back to Category"
      onBack={handleBack}
      onSelect={handleSelect}
    >
      <DetailColumns columns={[

        <DetailPane
          title="Section Details"
          actions={[
            {title: 'Edit Section', icon: <EditIcon/>, onClick: () => toggleDialog('editSection', true)},
            {title: 'Delete Section', icon: <DeleteIcon/>, onClick: () => toggleDialog('deleteSection', true)},
          ]}
          details={[
            {title: 'Title', value: section.title}
          ]}
          dialogs={[
            (props) => (
              <FormDialog
                {...props}
                title="Edit Section"
                open={openDialogs['editSection'] ?? false}
                onClose={() => toggleDialog('editSection', false)}
                render={(props) => (
                  <AuditTypeSectionForm
                    {...props}
                    type={type}
                    section={section}
                    onSaved={onSaved}/>
                )}
              />
            ),
          ]}
        />,

        <DetailPane
          title="Admin Details"
          details={[
            {title: 'Created', value: dateTime(section.created_at)},
            {title: 'Last Updated', value: dateTime(section.updated_at)}
          ]}
        />,
      ]}/>

      <Paper component={Box} marginTop={2} overflow="hidden">
        <Questions type={type} section={section} onSaved={onSaved} onUpdated={onTypeUpdated}/>
      </Paper>

      <ConfirmationDialog
        title="Delete Section"
        message="Are you sure you want to delete this section?"
        open={openDialogs['deleteSection']}
        onClose={handleDelete}
      />
    </Navigation>
  ) : null;
};

export default SectionDetail;

import {dateTime, DetailColumns, DetailPane, FormDialog, name, useDialogs, useErrors} from '@management-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Paper} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import YesNo from '../../../../components/YesNo';
import UserForm from '../../forms/UserForm';
import Table from '../Audits/Table';
import Permissions from './Permissions';
import Wrapper from './Wrapper';

const User = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({module: 'Sorry the user could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);


  const loadUser = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.user.getUser(id).then(retrieved => {
        setUser(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('module', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadUser(match.params?.id);
    }
  }, [history, match, user, loadUser]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (user) {
      header = name(user);
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [user]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setUser(updated);
    } else if (user?.id) {
      loadUser(user.id);
    }
  }, [toggleDialog, setUser, loadUser, user]);

  return (
    <Wrapper title={title} module={user} loading={loading} crumbs={crumbs} errors={errors}>
      {user?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="User Details"
              actions={[
                {title: 'Edit User', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
              ]}
              details={[
                {title: 'Name', value: name(user)},
                {title: 'Email Address', value: user.email},
                {title: 'Is Active?', value: <YesNo value={user.is_active}/>},
                {title: 'Is Developer?', value: <YesNo value={user.is_developer}/>}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit User"
                    open={openDialogs['edit'] ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <UserForm
                        {...props}
                        user={user}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(user.created_at)},
                {title: 'Last Updated', value: dateTime(user.updated_at)}
              ]}
            />,
          ]}/>

          <DetailColumns columns={[
            <DetailPane title="Permissions" collapseChildrenHeight={false}>
              <Permissions user={user} onUpdated={setUser}/>
            </DetailPane>
          ]}/>

          {user.permissions['can_manage_audits'] ? (
            <Paper component={Box} marginTop={2} width="100%">
              <Table user={user}/>
            </Paper>
          ) : null}
        </>
      ) : null}
    </Wrapper>
  );
};

export default User;

import {styled} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useAuditStatuses} from '../../../../hooks';
import numeral from 'numeral';
import AuditsDialog from './AuditsDialog';

const Container = styled('div')(() => ({
  display: 'flex',

  '&.condensed': {
    fontSize: '75%',
  }
}));

const Status = styled('div')(({theme}) => ({
  borderLeft: `2px solid ${theme.palette.grey['100']}`,
  borderRight: `2px solid ${theme.palette.grey['100']}`,
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: `${theme.spacing(2)} 0`,

  '&:first-of-type': {
    borderLeft: 0,
  },

  '&:last-of-type': {
    borderRight: 0,
  },

  '& > *': {
    margin: 0,
    padding: 0,
    textAlign: 'center'
  },

  '& > h3': {
    color: 'var(--status-colour)',
    fontSize: '1.8em',
  },

  '& > h4': {
    color: theme.palette.grey['700'],
    fontSize: '1.4em',
    fontWeight: '400',
  },
}));

const Summary = ({summary, condensed = false}) => {
  const statuses = useAuditStatuses();
  const [total, setTotal] = useState(-1);
  const [dialog, setDialog] = useState({title: '', audits: []});
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setTotal(summary.map(({audits}) => audits.length).reduce((running, amount) => running + amount, 0));
  }, [summary]);

  const getColour = useCallback((key) => statuses.find(s => s.key === key).colour, [statuses]);
  const calculatePercentage = useCallback((count) => numeral(count / total).format('0.0%'), [total]);

  const handleSelect = useCallback((title, audits) => {
    setDialog({title, audits});
    setShowDialog(true);
  }, []);

  return (
    <Container className={condensed ? 'condensed' : ''}>
      {summary.map(({title, key, audits}, index) => (
        <Status style={{'--status-colour': getColour(key)}} key={index} onClick={() => handleSelect(title, audits)}>
          <h3>{title}</h3>
          {total > -1 ? (
            <h4><strong>{audits.length} / {total}</strong>&nbsp;({calculatePercentage(audits.length)})</h4>
          ) : null}
        </Status>
      ))}
      <AuditsDialog {...dialog} open={showDialog} onClose={() => setShowDialog(false)}/>
    </Container>
  );
}

export default Summary;

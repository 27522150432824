import {makeStyles} from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {compareDateFormat} from '../../vars';
import NavigateByMonth from './NavigateByMonth';
import Week from './Week';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

/**
 * A component to display a month of days
 *
 * @module MonthlyCalendar
 *
 * @param {moment.Moment} month The start date of the displayed month
 * @param {ICalendarEvent[]} events An array of events to display
 * @param {function} onSelectMonth A function which is executed when the month is changed
 * @param {function} onSelectDay An action for when a day is selected
 * @param {function} onSelectEvent An action for when an event is selected
 * @param {function} onRenderEvents An action to custom render the events within a day
 *
 * @example
 * <MonthlyCalendar
 *   month={moment().startOf('month')}
 *   events={[
 *     {when: {date: moment(), start: moment(), end: moment().add(1, 'hour')}, title: 'Event', colour: '#00ff00'}
 *   ]}
 *   onSelectMonth={(month) => console.log(month)}
 *   onSelectDay={(day) => console.log(day)}
 *   onSelectEvent={(event) => console.log(event)}
 *   onRenderEvents={(events) => <h1>Events</h1>}
 * />
 *
 */
const MonthlyCalendar = (
  {
    month,
    events = [],
    onSelectMonth,
    onSelectDay,
    onSelectEvent,
    onRenderEvents
  }
) => {

  const classes = useStyles();
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    const start = month.clone().startOf('isoWeek');
    const end = month.clone().endOf('month').startOf('isoWeek');
    let date = start.clone();
    const compareDate = 'DD-MM-YYYY';
    const compareMonth = 'MM-YYYY';
    const compareWeek = 'YYYYWW';
    const list = [];
    while (parseInt(date.format(compareWeek)) <= parseInt(end.format(compareWeek))) {
      list.push([...Array(7).keys()].map((d) => {
        const day = date.clone().add(d, 'day');
        return {
          date: day,
          today: day.format(compareDate) === moment().format(compareDate),
          current: day.format(compareMonth) === month.format(compareMonth),
          events: events.filter(e => e.when.date.format(compareDateFormat) === day.format(compareDateFormat))
        };
      }))
      date.add(1, 'week');
    }
    setWeeks(list);
  }, [month, events]);

  return (
    <div className={classes.container}>
      <NavigateByMonth month={month} onSelectMonth={onSelectMonth}/>
      {weeks.map((week, index) => (
        <Week
          key={index}
          dayHeaders={index === 0}
          month={month}
          week={week}
          onSelectDay={onSelectDay}
          onSelectEvent={onSelectEvent}
          onRenderEvents={onRenderEvents}
        />
      ))}
    </div>
  )
};

MonthlyCalendar.propTypes = {
  month: PropTypes.object,
  events: PropTypes.array,
  onSelectMonth: PropTypes.func,
  onSelectDay: PropTypes.func,
  onSelectEvent: PropTypes.func,
  onRenderEvents: PropTypes.func
};

export default MonthlyCalendar;

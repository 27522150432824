import {Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import ArloOrganisationLookup from '../ArloOrganisationLookup';

const Fields = ({school = {}, prefix = '', multiplePanes = true, children}) => {
  const services = useContext(ServiceContext);
  const [types, setTypes] = useState([]);
  const [ratings, setRatings] = useState([]);
  const optionsLoaded = useRef(false);

  useEffect(() => {
    if (!optionsLoaded.current) {
      optionsLoaded.current = true;
      services.attribute.schoolType.getAttributes({}, 1, 500).then((retrieved) => {
        setTypes(retrieved.map(({id, name}) => ({title: name, value: id})));
        services.attribute.ofstedRating.getAttributes({}, 1, 500).then((retrieved) => {
          setRatings(retrieved.map(({id, name}) => ({title: name, value: id})));
        }).catch(() => null);
      }).catch(() => null);
    }
  }, [services]);

  const handleReset = useCallback((subject) => {
    if (subject.type) {
      subject.type_id = `${subject.type.id}`;
    }
    if (subject.rating) {
      subject.rating_id = `${subject.rating.id}`;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={school}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <ArloOrganisationLookup
              nameField="name"
              idField="arlo_id"
              prefix={prefix}
            />,
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name for this school'}}
            />,
            <Select
              name="type_id"
              prefix={prefix}
              label="Type"
              options={types}
            />,
            <Select
              name="rating_id"
              prefix={prefix}
              label="Ofsted Rating"
              options={ratings}
            />,
            <TextField
              name="arlo_id"
              prefix={prefix}
              label="Arlo ID"
              rules={{required: 'Please enter an Arlo ID for this school'}}
            />,
          ]
        }, {
          title: '',
          fields: []
        },
      ]}
      title={multiplePanes ? null : 'School Details'}
    >{children}</Panes>
  );
};

export default Fields;

import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {reverse} from 'named-urls';
import React, {Fragment, useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import routes from '../../../../routes';
import AuditProgressBar from './AuditProgressBar';
import QuestionsDialog from './QuestionsDialog';
import SectionProgressBar from './SectionProgressBar';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),

    '& > .MuiTypography-root': {
      textAlign: 'center',

      '&.MuiTypography-h4': {
        color: theme.palette.primary.main,
        margin: 0,
      },

      '&.MuiTypography-h6': {
        color: theme.palette.primary.main,
        margin: `${theme.spacing(4)} 0 ${theme.spacing(2)}`,

        '& a': {
          color: theme.palette.primary.main,
          textDecoration: 'none',

          '&:hover': {
            textDecoration: 'underline',
          }
        }
      }
    },
  }
}));

const Overview = ({audit}) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState({open: false, questions: [], title: ''});

  const filterQuestions = useCallback((title, questions) => {
    return questions.filter(({rating}) => (
      (title === 'No Rating' && !rating)
      ||
      (title === 'Not Met' && `${rating}` === '1')
      ||
      (title === 'Partly Met' && `${rating}` === '2')
      ||
      (title === 'Fully Met' && `${rating}` === '3')
    ));
  }, []);

  const handleDialog = useCallback((title = null, section = null) => {
    if (!title) {
      setDialog({...dialog, open: false})
    } else {
      if (section) {
        setDialog({
          open: true,
          questions: filterQuestions(title, section.questions.map(q => ({...q, sectionIndex: section.index}))),
          title: `${section.title} - ${title}`
        });
      } else {
        let questions = [];
        audit.sections.forEach((s) => questions = [...questions, ...s.questions.map(q => ({
          ...q,
          sectionIndex: s.index
        }))]);
        setDialog({open: true, questions: filterQuestions(title, questions), title});
      }
    }
  }, [dialog, audit, filterQuestions]);

  return (
    <div className={classes.container}>
      <Typography variant="h4">Report Overview</Typography>
      <Typography variant="h6">Full Report Summary</Typography>
      <AuditProgressBar audit={audit} onSelect={title => handleDialog(title)}/>
      {audit.sections.map((section, index) => (
        <Fragment key={index}>
          <Typography variant="h6">
            <Link to={reverse(routes.audits.section, {
              id: audit.id,
              tab: 'ratings',
              sectionID: section.id
            })}>{section.index}. {section.title}</Link>
          </Typography>
          <SectionProgressBar section={section} onSelect={title => handleDialog(title, section)}/>
        </Fragment>
      ))}
      <QuestionsDialog {...dialog} onClose={() => handleDialog()}/>
    </div>
  );
};

export default Overview;

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import React, {useCallback, useEffect, useRef, useState} from 'react';

const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.grey['300']}`;
  const buttonSize = '60px';
  return {
    nav: {
      borderBottom: border,
      display: 'flex',

      '& h2': {
        alignItems: 'center',
        borderLeft: border,
        borderRight: border,
        display: 'flex',
        flex: 1,
        fontSize: '1.4em',
        fontWeight: 700,
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        textAlign: 'center',
      },

      '& button': {
        alignItems: 'center',
        background: 'none',
        border: 0,
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        display: 'flex',
        height: buttonSize,
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        width: buttonSize,

        '&:focus': {
          outline: 0,
        }
      }
    },

    months: {
      borderBottom: border,
      display: 'flex',
    },

    month: {
      borderRight: border,
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',

      '& > span': {
        alignItems: 'center',
        display: 'flex',
        fontSize: '1.2em',
        fontWeight: '700',
        justifyContent: 'center',
        padding: theme.spacing(1),
      },

      '&:last-child': {
        borderRight: 0
      }
    },

    current: {
      backgroundColor: theme.palette.grey['200'],
      color: theme.palette.primary.main
    },

    now: {
      backgroundColor: theme.palette.grey['100']
    },
  };
});

const NavigateByMonth = ({month, onSelectMonth}) => {
  const classes = useStyles();
  const [year, setYear] = useState(0);
  const [months, setMonths] = useState([]);
  const initialised = useRef(false);

  useEffect(() => {
    if (month && !initialised.current) {
      initialised.current = true;
      setYear(month.year());
    }
  }, [month]);

  useEffect(() => {
    if (year) {
      const thisMonth = moment().startOf('month');
      const compareMonth = 'YYYY-MM';
      const list = [];
      const date = moment().year(year).startOf('year');
      for (let m = 0; m < 12; m++) {
        const workingMonth = date.clone().month(m).startOf('month');
        list.push({
          title: workingMonth.format('MMM'),
          start: workingMonth.clone(),
          now: workingMonth.format(compareMonth) === thisMonth.format(compareMonth),
          current: workingMonth.format(compareMonth) === month.format(compareMonth),
        });
      }

      setMonths(list);
    }
  }, [year, month]);

  const handleNext = useCallback(() => {
    setYear(year + 1);
  }, [year]);

  const handleBack = useCallback(() => {
    setYear(year - 1);
  }, [year]);

  return year ? (
    <>
      <div className={classes.nav}>
        <button onClick={handleBack}><ChevronLeftIcon/></button>
        <h2><span>{year}</span></h2>
        <button onClick={handleNext}><ChevronRightIcon/></button>
      </div>
      <div className={classes.months}>
        {months.map((month, monthIndex) => (
          <div
            key={monthIndex}
            className={[
              classes.month,
              month.current ? classes.current : '',
              month.now ? classes.now : ''
            ].join(' ')}
            onClick={() => onSelectMonth(month.start)}
          >
            <span>{month.title}</span>
          </div>
        ))}
      </div>
    </>
  ) : null;
};

export default NavigateByMonth;

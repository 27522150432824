import {useCallback, useEffect, useRef, useState} from 'react';

/**
 * A hook to manage open dialogs (or anything which is toggled true/false)
 *
 * @function useDialogs
 *
 * @param {string[]} dialogs An array of dialog names
 *
 * @returns {{openDialogs: object, toggleDialog: function}} `{openDialogs: object, toggleDialog: function}`
 *
 * `openDialogs` - An object which has a key for each dialog. The corresponding value is a boolean to mark whether that
 * dialog is visible.
 *
 * `toggleDialog` - A function which takes the name of a dialog along with a boolean to mark whether it should be visible.
 *
 * @example
 * const {openDialogs, toggleDialog} = useDialogs(['new', 'edit']);
 * console.log(openDialogs); // Output {new: false, edit: false}
 * toggleDialog('new', true);
 * console.log(openDialogs); // Output {new: true, edit: false}
 * toggleDialog('edit', true);
 * console.log(openDialogs); // Output {new: true, edit: false}
 *
 */
export function useDialogs(dialogs) {
  const dialogsRef = useRef({});
  const [openDialogs, setOpenDialogs] = useState(dialogs.reduce((obj, key) => ({...obj, [key]: false}), {}));

  useEffect(() => {
    dialogsRef.current = openDialogs;
  }, [openDialogs]);

  const toggleDialogs = useCallback((dialogs) => {
    const state = {...dialogsRef.current};
    setOpenDialogs({...state, ...dialogs});
  }, []);

  const toggleDialog = useCallback((dialog, isOpen) => {
    const update = {};
    update[dialog] = isOpen;
    toggleDialogs(update);
  }, [toggleDialogs]);

  return {openDialogs, toggleDialog, toggleDialogs};
}

export class CacheService {

  write(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  read(key, initial = null) {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    } else {
      return initial;
    }
  }
}

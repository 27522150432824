import {Box, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import TooltipButton from './TooltipButton';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: `0 0 ${theme.spacing(2)}`,
    padding: `0 ${theme.spacing(1)}`
  }
}));

/**
 * A menu of tooltip buttons with an optional title
 *
 * @module TooltipButtonMenu
 *
 * @param {string} title The title to display above the buttons
 * @param {ITooltipButton[]} buttons The buttons to display
 * @param {function} onClick The function to execute when a button is clicked
 *
 * @example
 * <TooltipButtonMenu
 *   title="Button Menu"
 *   buttons={[
 *     {title: 'Button A', description: 'First button', label: 'buttonA'},
 *     {title: 'Button B', description: 'Second button', label: 'buttonB'}
 *   ]}
 *   onClick={({title}) => console.log(`${title} was clicked!`)}
 * />
 *
 */
const TooltipButtonMenu = ({title, buttons, onClick}) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" padding={1}>
      {title ? <Typography className={classes.title} variant="subtitle2" color="primary">{title}</Typography> : null}
      <Box display="flex" flexWrap="wrap">
        {buttons.map((button, index) => (
          <TooltipButton
            key={index}
            title={button.title}
            description={button.description}
            label={button.label}
            onClick={onClick}
          />
        ))}
      </Box>
    </Box>
  )
};

TooltipButtonMenu.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
  onClick: PropTypes.func
};

export default TooltipButtonMenu;

import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const SettingsFields = ({settings = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={settings}
      prefix={prefix}
      panes={[
        {
          title: 'General Settings',
          fields: [
            <TextField
              name="sender"
              prefix={prefix}
              label="Trigger Email Sender"
            />,
          ]
        },
        {
          title: 'Contact Details (for report PDF)',
          fields: [
            <TextField
              name="companyName"
              prefix={prefix}
              label="Registered Company Name"
            />,
            <TextField
              name="phone"
              prefix={prefix}
              label="Telephone Number"
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
            />,
            <TextField
              name="website"
              prefix={prefix}
              label="Website Address"
            />,
          ]
        },
        {
          title: 'Arlo Configuration',
          fields: [
            <TextField
              name="arloPlatform"
              prefix={prefix}
              label="Platform"
            />,
            <TextField
              name="arloUsername"
              prefix={prefix}
              label="Username"
            />,
            <TextField
              name="arloPassword"
              prefix={prefix}
              label="Password (hidden)"
              fieldProps={{type: 'password'}}
            />,
          ]
        },
      ]}
      title={multiplePanes ? null : 'Settings'}
    >{children}</Panes>
  );
};

SettingsFields.getValues = (
  {
    sender,
    companyName,
    phone,
    email,
    website,
    arloPlatform,
    arloUsername,
    arloPassword
  }
) => ({
  sender,
  companyName,
  phone,
  email,
  website,
  arloPlatform,
  arloUsername,
  ...(arloPassword ? {arloPassword} : {})
});

export default SettingsFields;

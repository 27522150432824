import {ConfirmationDialog, DataTable} from '@management-ui/core';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import AuditTypeForm from '../../forms/AuditTypeForm';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const [selected, setSelected] = useState(null);
  const [showRestore, setShowRestore] = useState(false);

  const handleSelect = useCallback((type) => {
    if (!archive) {
      history.push(reverse(routes.auditTypes.detail, {id: type.id}));
    } else {
      setSelected(type);
      setShowRestore(true);
    }
  }, [history, archive]);

  const handleRestore = useCallback((confirmed) => {
    setShowRestore(false);
    if (confirmed) {
      services.auditType.type.restoreType(selected).then(() => {
        if (tableRef.current) {
          tableRef.current.refresh();
        }
      }).catch(() => null);
    }
  }, [services, selected])

  return (
    <>
      <DataTable
        ref={tableRef}
        title="Categories"
        columns={useMemo(() => [
          {title: 'Title', field: 'title'}
        ], [])}
        loadData={useCallback(query => new Promise((resolve, reject) => {
          services.auditType.type.getTableTypes(query, archive ? {archive: 'only'} : {})
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(() => {
            reject();
          });
        }), [services, archive])}
        newForm={!archive ? {
          title: 'Add New Category',
          render: (props) => <AuditTypeForm type={{}} {...props}/>,
          onSaved: handleSelect
        } : null}
        onRowClick={handleSelect}
      />
      <ConfirmationDialog
        title="Restore Category"
        message="Are you sure you want to restore this category? Doing so will restore any questions which are archived and required for this category."
        open={showRestore}
        onClose={handleRestore}
      />
    </>
  );
};

export default Table;

import {Paper, styled} from '@mui/material';

const Surface = styled(Paper)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`
}));

export default Surface;

import {ConfirmationDialog, FormDialog, ReorderingPanel} from '@management-ui/core';
import {Edit} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {Box} from '@mui/material';
import {useTheme} from '@mui/styles';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import AttributeForm from '../../forms/AttributeForm';

const Attribute = ({pluralName, singularName, serviceName}) => {
  const services = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState({});
  const theme = useTheme();

  const formatAttributes = useCallback((list) => (
    list.map((attribute) => ({
      entity: attribute,
      attributes: [attribute.name]
    }))
  ), []);

  const loadAttributes = useCallback(() => {
    setLoading(true);
    services.attribute[serviceName].getAttributes().then(retrieved => {
      setLoading(false);
      setAttributes(formatAttributes(retrieved));
    }).catch(() => setLoading(false));
  }, [services, serviceName, formatAttributes]);

  useEffect(() => {
    loadAttributes();
  }, [loadAttributes]);

  const handleReorder = useCallback((reordered) => {
    setAttributes(formatAttributes(reordered));
    return services.attribute[serviceName].reorderAttributes(reordered.map((attribute, index) => ({
      id: attribute.id,
      index: index + 1
    })));
  }, [services, serviceName, formatAttributes]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.attribute[serviceName].deleteAttribute(selected).then((updated) => {
        setLoading(false);
        setAttributes(formatAttributes(updated));
      }).catch(() => setLoading(false));
    }
  }, [services, serviceName, selected, formatAttributes]);

  const handleSaved = useCallback((updated) => {
    setAttributes(formatAttributes(updated));
    setShowEdit(false);
  }, [formatAttributes]);

  return (
    <Box borderTop={`4px solid ${theme['palette'].grey['100']}`}>
      <ReorderingPanel
        title={`Manage ${pluralName}`}
        items={attributes}
        loading={loading}
        onLoading={setLoading}
        controls={[
          {
            title: `Edit ${singularName}`,
            icon: <Edit/>,
            onClick: (section) => setSelected(section) || setShowEdit(true)
          },
          {
            title: `Delete ${singularName}`,
            icon: <DeleteIcon/>,
            onClick: (section) => setSelected(section) || setShowDelete(true)
          }
        ]}
        onNew={() => setSelected(null) || setShowEdit(true)}
        onReorder={handleReorder}
      />
      <FormDialog
        title={selected ? `Edit ${singularName}` : `Add New ${singularName}`}
        open={showEdit}
        maxWidth="md"
        onClose={() => setShowEdit(false)}
        render={(props) => (
          <AttributeForm
            {...props}
            attribute={selected ?? {}}
            singularName={singularName}
            serviceName={serviceName}
            onSaved={handleSaved}
          />
        )}
      />
      <ConfirmationDialog
        title={`Delete ${singularName}`}
        message={`Are you sure you want to delete this ${singularName.toLowerCase()}?`}
        open={showDelete}
        onClose={handleDelete}
      />
    </Box>
  );
}

export default Attribute;

import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Alert,
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  spacer: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerInner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  toolbarPreset: theme.mixins.toolbar,
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  user: {
    marginRight: theme.spacing(1),

    '& span': {
      fontWeight: 700,
    }
  },
  drawerPaper: {
    width: drawerWidth,
  },
  link: {
    transition: '0.25s opacity ease-in-out',

    '& span': {
      fontSize: '1.1em',
    },

    '&:hover': {
      color: theme.palette.secondary.main,
    }
  },
  disabled: {
    opacity: 0.5,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  progress: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0
  }
}));

/**
 * A component to provide a container for authenticated systems including header and sidebar navigation
 *
 * @module Authenticated
 *
 * @param {string} title The title of the area
 * @param {string} logo The logo to display in the header
 * @param {number|string} logoWidth The width of the logo displayed in the header
 * @param {INavItem[]} nav An array of navigation items to display in the sidebar
 * @param {string} userName The name of the person logged-in to show a 'Welcome' message
 * @param {function} onLogout A callback to log the user out
 * @param {any} sidebarContent Content to display below the sidebar navigation
 * @param {node} children Child nodes to display as content
 *
 * @example
 * <Authenticated
 *   title="Authenticated"
 *   logo="/images/logo.png"
 *   logoWidth={300}
 *   nav={[
 *     {title: 'Dashboard', route: '/dashboard', icon: <DashboardIcon/>},
 *     {title: 'Archive', route: '/archive', icon: <ArchiveIcon/>, disabled: true}
 *   ]}
 *   userName="Joe Blogs"
 *   onLogout={() => console.log('Log out')}
 *   sidebarContent={<p>Sidebar</p>}
 * >
 *   <Switch>
 *     <Route
 *       exact
 *       path="/dashboard"
 *       render={routeProps => <Dashboard {...props} {...routeProps}/>}/>
 *     <Route
 *       exact
 *       path="/archive"
 *       render={routeProps => <Archive {...props} {...routeProps}/>}/>
 *   </Switch>
 * </Authenticated>
 *
 */
const Authenticated = (
  {
    title,
    logo,
    logoWidth = 0,
    nav,
    userName,
    onLogout,
    sidebarContent = null,
    children
  }
) => {
  const classes = useStyles();
  const theme = useTheme();
  const [errorOpen, setErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setLoading(true);
    onLogout();
  };

  const drawer = (
    <div className={classes.drawerInner}>
      <div className={`${classes.toolbarPreset} ${classes.toolbar}`}>
        {typeof logo === 'string' ? (
          <img src={logo} alt={title} style={{width: logoWidth ?? '60%'}}/>
        ) : logo}
      </div>
      <Divider/>
      <List>
        {nav.map((item, index) => {
          let itemProps;
          if (item.disabled) {
            itemProps = {onClick: (e) => e.preventDefault()};
          } else {
            if (item.route) {
              itemProps = {component: RouterLink, to: `${item.route}`};
            } else {
              itemProps = {onClick: item.onClick};
            }
          }
          return (
            <ListItem key={index}
                      className={[classes.link, item.disabled ? classes.disabled : ''].join(' ')} {...itemProps} button>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.title}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      {sidebarContent}
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
            <MenuIcon/>
          </IconButton>
          <div className={classes.spacer}/>
          {userName ? <Typography className={classes.user}>Welcome, <span>{userName}</span></Typography> : null}
          {onLogout ? (
            <>
              <IconButton color="inherit" edge="end" onClick={handleProfileMenu}>
                <AccountCircle/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={open}
                onClose={handleCloseProfileMenu}
              >
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              </Menu>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{paper: classes.drawerPaper}}
            ModalProps={{keepMounted: true}}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbarPreset}/>
        {children}
      </main>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Sorry you couldn't log out at this time
        </Alert>
      </Snackbar>
      {loading ? <LinearProgress className={classes.progress}/> : null}
    </div>
  );
};

Authenticated.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  logoWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  nav: PropTypes.array,
  userName: PropTypes.string,
  onLogout: PropTypes.func,
  sidebarContent: PropTypes.any
};

export default Authenticated;

import {Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
  },

  nav: {
    display: 'flex',
    flex: '0 0 200px',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
    width: 200,
  },

  manage: {
    flex: 1,
  },

  sidebar: {
    '&.MuiPaper-root': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'hidden',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2)
    }
  },

  link: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    textAlign: 'left',

    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main
    },
  },

  back: {
    fontWeight: '700',
    color: theme.palette.common.black,

    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main
    },
  },

  current: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

const Navigation = ({items, current, backLabel, onBack, onSelect, children}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <Paper className={classes.sidebar}>
          {onBack ? (
            <button
              className={[classes.link, classes.back].join(' ')}
              onClick={onBack}
            >
              &lt;&lt; {backLabel}
            </button>
          ) : null}
          {items.map((item, index) => (
            <button
              key={index}
              className={[classes.link, current && `${current}` === `${item.id}` ? classes.current : ''].join(' ')}
              onClick={() => onSelect(item)}
            >
              {item.menuTitle}
            </button>
          ))}
        </Paper>
      </div>
      <div className={classes.manage}>
        {children}
      </div>
    </div>
  );
};

export default Navigation;

import {Box, Breadcrumbs as MatBreadcrumbs, Link, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

/**
 * A breadcrumb trail built with objects including link options.
 *
 * @module Breadcrumbs
 *
 * @param {IBreadcrumb[]} crumbs The crumbs to display
 *
 * @example
 * <Breadcrumbs
 *   crumbs={[
 *     {title: 'Parent', link: '/parent'},
 *     {title: 'Child'}
 *   ]}
 * />
 *
 */
const Breadcrumbs = ({crumbs}) => (
  <Box paddingLeft={2} paddingBottom={2}>
    <MatBreadcrumbs aria-label="breadcrumb">
      {crumbs.map((crumb, index) => (
        crumb.link ? (
          <Link
            key={index}
            underline="hover"
            component={RouterLink}
            color="textPrimary"
            variant="body2"
            to={crumb.link}
          >{crumb.title}</Link>
        ) : (
          <Typography key={index} color="textPrimary" variant="body2">{crumb.title}</Typography>
        )
      ))}
    </MatBreadcrumbs>
  </Box>
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array
};

export default Breadcrumbs;

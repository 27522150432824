import {Breadcrumbs, DataTable, name} from '@management-ui/core';
import {Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../components/Services';
import YesNo from '../../../components/YesNo';
import routes from '../../../routes';
import UserForm from '../forms/UserForm';

const Users = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((user) => {
    history.push(reverse(routes.users.detail, {id: user.id}));
  }, [history]);

  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Users'}]}/>
      <Paper>
        <DataTable
          title="Users"
          columns={useMemo(() => [
            {title: 'Name', field: 'name', render: data => name(data)},
            {title: 'Email', field: 'email'},
            {title: 'Active?', field: 'is_active', render: data => <YesNo value={data.is_active}/>},
          ], [])}
          loadData={query => new Promise((resolve, reject) => {
            services.user.getTableUsers(query)
              .then(response => {
                resolve({
                  data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
                });
              }).catch(() => {
              reject();
            });
          })}
          newForm={{
            title: 'Add New User',
            render: (props) => <UserForm user={{}} {...props}/>,
            onSaved: goToDetail
          }}
          onRowClick={goToDetail}
        />
      </Paper>
    </div>
  );
};

export default Users;

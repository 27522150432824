import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  address: {
    margin: 0,
    padding: 0,
  }
}));

/**
 * A simple component to combine a multi-line address into an address tag.
 *
 * @module Address
 *
 * @param {array} address An array of strings containing the address components to display.
 *
 * @example
 * <Address address={['Address Line 1', 'Town', 'Postcode']}/>
 *
 */
const Address = ({address}) => {
  const classes = useStyles();
  if (address.length) {
    return (
      <address className={classes.address}>{address.map((a, index) => <span key={index}>{a}<br/></span>)}</address>
    );
  } else {
    return '-';
  }
};

Address.propTypes = {
  address: PropTypes.array,
};

export default Address;

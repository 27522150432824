import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AccessHolder from './AccessHolder';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import ResetPassword from './ResetPassword';

/**
 * A pre-made interface for handling access control (log-in, forgot and reset password)
 *
 * @module Access
 *
 * @param {string} title The a title to use with the logo
 * @param {string} logo A logo to display in the header
 * @param {number} logoWidth The width of the logo (default 200px)
 * @param {string} company The name of the company to display in the footer
 * @param {IAccessRoutes} routes The routes to use within the application
 * @param {IAccessActions} actions The handlers functions for each action
 * @param {object} props Props to pass onto relevant screen components
 *
 * @example
 * <Access
 *   title="The Application Title"
 *   logo={logo}
 *   logoWidth={400}
 *   company="The Company Name"
 *   routes={{login: '/login', forgotPassword: '/forgot-password', resetPassword: '/reset-password'}}
 *   actions={{
 *     onLogin: (email, password) => new Promise(resolve => console.log({email, password}) || resolve(),
 *     onForgotPassword: (email) => new Promise(resolve => console.log({email}) || resolve()
 *     onResetPassword: (email, password, token) => {
 *       new Promise(resolve => console.log({email, password, token});
 *       resolve();
 *     }
 *   }}
 *   {...props}
 * />
 *
 */
const Access = (
  {
    title,
    logo,
    logoWidth = 200,
    company,
    routes,
    actions,
    ...props
  }
) => {
  return (
    <AccessHolder title={title} logo={logo} logoWidth={logoWidth} company={company}>
      <Switch>
        <Route
          exact
          path={routes.login}
          render={routeProps => <Login {...props} {...routeProps} routes={routes} onLogin={actions.onLogin}/>}/>
        <Route
          exact
          path={routes.forgotPassword}
          render={routeProps => <ForgotPassword {...props} {...routeProps} routes={routes}
                                                onForgotPassword={actions.onForgotPassword}/>}/>
        <Route
          exact
          path={routes.resetPassword}
          render={routeProps => <ResetPassword {...props} {...routeProps} routes={routes}
                                               onResetPassword={actions.onResetPassword}/>}/>
      </Switch>
    </AccessHolder>
  );
};

Access.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.string,
  logoWidth: PropTypes.number,
  company: PropTypes.string,
  routes: PropTypes.object,
  actions: PropTypes.object
};

export default Access;

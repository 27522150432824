import {CheckBoxOutlineBlank, CheckBoxOutlined} from '@mui/icons-material';
import {styled} from '@mui/material';
import React, {Fragment} from 'react';
import Text from '../../../../components/Text';
import {FULLY_MET, NOT_MET, PARTLY_MET} from '../../../../constants';

const Container = styled('div')(({theme}) => ({
  border: `1px solid ${theme.palette.grey['300']}`,
  borderTop: 0,
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
  width: '100%',
}));

const Section = styled('div')(({theme}) => ({
  borderTop: `1px solid ${theme.palette.grey['300']}`,
  background: theme.palette.grey['100'],
  display: 'flex',
  fontWeight: '600',
  padding: theme.spacing(1)
}));

const Question = styled('div')(({theme}) => ({
  borderTop: `1px solid ${theme.palette.grey['300']}`,
  cursor: 'pointer',
  display: 'flex',

  '& .toggle': {
    padding: theme.spacing(1),
  },

  '& .body': {
    borderLeft: `1px solid ${theme.palette.grey['300']}`,
    flex: 1,
    padding: theme.spacing(1),
  }
}));

const Rating = styled('div')(({theme}) => ({
  flex: '0 0 120px',
  fontWeight: '700',
  padding: theme.spacing(1),
  width: 120,

  '&.rating0': {
    backgroundColor: theme.palette.grey['100'],
  },

  '&.rating1': {
    backgroundColor: NOT_MET,
    color: theme.palette.common.white,
  },

  '&.rating2': {
    backgroundColor: PARTLY_MET,
    color: theme.palette.common.white,
  },

  '&.rating3': {
    backgroundColor: FULLY_MET,
    color: theme.palette.common.white,
  },
}));

const Questions = ({audit, selected, onSelect}) => {
  return audit ? (
    <Container>
      {audit.sections.map((section, sectionIndex) => (
        <Fragment key={sectionIndex}>
          <Section>{section.index}. {section.title}</Section>
          {section.questions.map((question, questionIndex) => {
            let rating = '-';
            switch (`${question.rating}`) {
              case '1':
                rating = 'Not Met';
                break;
              case '2':
                rating = 'Partly Met';
                break;
              case '3':
                rating = 'Fully Met';
                break;
              default:
                break;
            }
            return (
              <Question key={`${sectionIndex}-${questionIndex}`} onClick={() => onSelect(question)}>
                <div className="toggle">
                  {selected.indexOf(`${question.id}`) >= 0 ? <CheckBoxOutlined/> : <CheckBoxOutlineBlank/>}
                </div>
                <div className="body">
                  <strong>{section.index}.{question.index}</strong>
                  <br/><Text text={question.body}/>
                </div>
                <Rating className={`rating${question.rating ?? 0}`}>{rating}</Rating>
              </Question>
            );
          })}
        </Fragment>
      ))}
    </Container>
  ) : null;
};

export default Questions;

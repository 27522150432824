import {FormDialog} from '@management-ui/core';
import {Box, Button} from '@mui/material';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import QuestionForm from '../../forms/QuestionForm';

const ManageQuestion = ({type, section, open, onClose, question, onSaved}) => {
  const services = useContext(ServiceContext);
  const [mode, setMode] = useState('');

  useEffect(() => {
    if (open) {
      if (question?.id) {
        setMode('edit');
      } else {
        setMode('');
      }
    }
  }, [open, question]);

  const handleClose = useCallback(() => {
    if (!question?.id && mode) {
      setMode('');
    } else {
      onClose();
    }
  }, [question, mode, onClose]);

  const handleSave = useCallback((data) => {
    if (mode === 'link') {
      return services.auditType.question.attachQuestion(type, section, data['question_id']);
    } else {
      return services.auditType.question.saveQuestion(type, section, data);
    }
  }, [services, type, section, mode]);

  return (
    <FormDialog
      title={question ? 'Edit Question' : 'Add New Question'}
      open={open}
      maxWidth="md"
      minHeight={mode === 'link' ? 400 : 0}
      onClose={handleClose}
      render={useMemo(() => (props) => (
        mode ? (
          <QuestionForm
            {...props}
            question={question ?? {}}
            link={mode === 'link'}
            searchFilters={{excludeSection: section.id, noHeaders: '1'}}
            onSave={handleSave}
            onSaved={onSaved}
          />
        ) : (
          <Box display="flex" flexWrap="wrap" width="100%" paddingY={6}>
            <Box flex="0 0 100%" width="100%" paddingBottom={4}>
              <Box textAlign="center" fontSize="1.4em">
                Would you like to create a new question or select one from the question bank?
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" flex={1} paddingRight={2}>
              <Button variant="contained" color="primary" onClick={() => setMode('link')}>Link Existing</Button>
            </Box>
            <Box display="flex" justifyContent="center" flex={1} paddingLeft={2}>
              <Button variant="contained" color="secondary" onClick={() => setMode('edit')}>Create New</Button>
            </Box>
          </Box>
        )
      ), [mode, section, question, handleSave, onSaved])}
    />
  );
};

export default ManageQuestion;

export function splitFields(data) {
  const groups = {};
  const root = {};
  for (let key of Object.keys(data)) {
    const match = key.match(/([A-Za-z0-9]+)__([A-Za-z0-9_]+)/);
    if (match) {
      if (!groups.hasOwnProperty(match[1])) {
        groups[match[1]] = {};
      }
      groups[match[1]][match[2]] = data[key];
    } else {
      root[key] = data[key];
    }
  }
  return {...groups, ...root};
}

export function prefixWithSeparator(prefix, separator = '__') {
  return prefix ? `${prefix}${separator}` : '';
}

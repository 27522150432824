import {Panes, prefixWithSeparator, Select} from '@management-ui/core';
import moment from 'moment';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';
import Questions from './Questions';

const Fields = ({audit = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  const [audits, setAudits] = useState([]);
  const [from, setFrom] = useState(null);
  const optionsLoaded = useRef(false);
  const {watch, setValue} = useFormContext();
  const fromName = useMemo(() => `${prefixWithSeparator(prefix)}from`, [prefix]);
  const questionsName = useMemo(() => `${prefixWithSeparator(prefix)}questions`, [prefix]);
  // noinspection JSCheckFunctionSignatures
  const fromID = watch(fromName, null);
  // noinspection JSCheckFunctionSignatures
  const questions = watch(questionsName, null);

  useEffect(() => {
    if (!optionsLoaded.current) {
      optionsLoaded.current = true;
      services.audit.getAudits({filter: {type: audit.type.id}}, 1, 50).then((retrieved) => {
        setAudits(retrieved.data.filter(({id}) => id !== audit.id).map(a => ({
          title: [a.school.name, a.title, a.status, moment(a.date).format('DD/MM/YYYY')].join(' - '),
          value: a.id,
          audit: a
        })));
      }).catch(() => null);
    }
  }, [services, audit]);

  useEffect(() => {
    if (audits.length && fromID) {
      const found = audits.find(a => `${a.value}` === `${fromID}`);
      setFrom(found ? found.audit : null);
    } else {
      setFrom(null);
    }
  }, [audits, fromID]);

  const handleSelectQuestion = useCallback((selected) => {
    const updated = [...(questions ?? [])];
    const index = updated.indexOf(`${selected.id}`);
    if (index >= 0) {
      updated.splice(index, 1);
    } else {
      updated.push(`${selected.id}`);
    }
    setValue(questionsName, updated)
  }, [setValue, questionsName, questions]);

  return (
    <Panes
      entity={audit}
      prefix={prefix}
      panes={[
        {
          title: 'Copy Audit',
          fields: [
            <Select
              name="from"
              prefix={prefix}
              label="Copy from"
              options={audits}
              rules={{required: 'Please select an audit'}}
            />,
            <Questions
              audit={from}
              selected={questions ?? []}
              onSelect={handleSelectQuestion}
            />
          ]
        }
      ]}
      title={''}/>
  );
};

export default Fields;

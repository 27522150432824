import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const UserForm = forwardRef(({user, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={user}
      type="user"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, first_name, last_name, email, title, password, is_active, is_developer}) => services.user.saveUser({
        id,
        first_name,
        last_name,
        email,
        title,
        password,
        is_active,
        is_developer
      })}
      onSaved={onSaved}
    />
  );
});

export default UserForm;

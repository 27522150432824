import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({section = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={section}
      prefix={prefix}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title for this section'}}
            />,
          ]
        }, {
          title: '',
          fields: []
        },
      ]}
      title={multiplePanes ? null : 'Section Details'}
    >{children}</Panes>
  );
};

export default Fields;

export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';

const auth = (
  state = {
    user: false,
    token: null,
    refresh: null,
    expires: 0
  },
  action,
) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        refresh: action.refresh,
        expires: action.expires
      };
    default:
  }
  return state;
};

export default auth;

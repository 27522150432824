import {Box, Container, CssBaseline, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A centred container component with a logo and copyright notice.
 *
 * @module AccessHolder
 *
 * @param {string} title The a title to use with the logo
 * @param {string} logo A logo to display in the header
 * @param {number} logoWidth The width of the logo (default 200px)
 * @param {string} company The name of the company to display in the footer
 * @param {node[]} children Inner content
 *
 * @example
 * <AccessHolder title="The Application Title" logo={logo} logoWidth={400} company="The Company Name">
 *   <p>Some Content to Display</p>
 * </AccessHolder>
 *
 */
const AccessHolder = ({title, logo, logoWidth = 200, company, children}) => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box alignItems="center" display="flex" flexDirection="column" marginTop={8}>
        <img className="mb-4" src={logo} width={logoWidth} alt={title}/>
        {children}
      </Box>
      <Box marginTop={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {`Copyright © ${company}`}
          {' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  );
}

AccessHolder.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.string,
  logoWidth: PropTypes.number,
  company: PropTypes.string
};

export default AccessHolder;

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {fullDate} from '../../formatters';
import {compareDateFormat} from '../../vars';
import Day from './Day';
import NavigateByYear from './NavigateByYear';

const useStyles = makeStyles(theme => {
  const border = `1px solid ${theme.palette.grey['300']}`;
  const buttonSize = '60px';
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },

    nav: {
      borderBottom: border,
      display: 'flex',

      '& h2': {
        alignItems: 'center',
        borderLeft: border,
        borderRight: border,
        display: 'flex',
        fontSize: '1.4em',
        flex: 1,
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        textAlign: 'center',

        '& span': {
          fontWeight: 700,

          '&:first-child': {
            marginRight: theme.spacing(1),
          },

          '&:last-child': {
            marginLeft: theme.spacing(1),
          }
        },
      },

      '& button': {
        alignItems: 'center',
        background: 'none',
        border: 0,
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        display: 'flex',
        height: buttonSize,
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        width: buttonSize,

        '&:focus': {
          outline: 0,
        }
      }
    },

    days: {
      display: 'flex',
      flex: 1,
    },

    day: {
      borderLeft: border,
      flex: 1,

      '&:first-child': {
        borderLeft: 0,
      }
    }
  };
});

/**
 * A component to display a week of time slots
 *
 * @module WeeklySlotCalendar
 *
 * @param {moment.Moment} week The start date of the displayed week
 * @param {ICalendarEvent[]} events An array of events to display
 * @param {ICalendarEvent[]} highlights An array of highlights to display
 * @param {boolean} hasHighlights A switch to determine if the calendar should display highlights
 * @param {boolean} showYearNav Whether to show the yearly navigator
 * @param {function} onSelectWeek A function which is executed when the week is changed
 * @param {function} onSelectSlot An action for when a time slot is selected
 * @param {function} onSelectEvent An action for when an event is selected
 * @param {function} onRenderEvents An action to custom render the events within a day
 *
 * @example
 * <WeeklySlotCalendar
 *   week={moment().startOf('isoWeek')}
 *   events={[
 *     {when: {date: moment(), start: moment(), end: moment().add(1, 'hour')}, title: 'Event', colour: '#00ff00'}
 *   ]}
 *   highlights={[
 *     {when: {date: moment(), start: moment(), end: moment().add(1, 'hour')}, title: 'Highlight', colour: '#ff0000'}
 *   ]}
 *   hasHighlights={true}
 *   showYearNav={true}
 *   onSelectWeek={(week) => console.log(week)}
 *   onSelectSlot={(slot) => console.log(slot)}
 *   onSelectEvent={(event) => console.log(event)}
 *   onRenderEvents={(events) => <h1>Events</h1>}
 * />
 *
 */
const WeeklySlotCalendar = (
  {
    week,
    events = [],
    highlights = [],
    hasHighlights = false,
    showYearNav = false,
    onSelectWeek,
    onSelectSlot,
    onSelectEvent,
    onRenderEvents
  }
) => {

  const classes = useStyles();
  const end = week.clone().add(6, 'days');

  const handleBack = useCallback((e) => {
    e.preventDefault();
    onSelectWeek(week.clone().subtract(1, 'week'));
  }, [week, onSelectWeek]);

  const handleNext = useCallback((e) => {
    e.preventDefault();
    onSelectWeek(week.clone().add(1, 'week'));
  }, [week, onSelectWeek]);

  return (
    <div className={classes.container}>
      {showYearNav ? <NavigateByYear navClass={classes.nav} week={week} onSelectWeek={onSelectWeek}/> : null}
      <div className={classes.nav}>
        <button onClick={handleBack}><ChevronLeftIcon/></button>
        <h2><span>{fullDate(week)}</span> to <span>{fullDate(end)}</span></h2>
        <button onClick={handleNext}><ChevronRightIcon/></button>
      </div>
      <div className={classes.days}>
        {[...Array(7).keys()].map((d, index) => {
          const date = week.clone().add(d, 'days');
          return (
            <div key={index} className={classes.day}>
              <Day
                day={date}
                events={events.filter(e => e.when.date.format(compareDateFormat) === date.format(compareDateFormat))}
                hasHighlights={hasHighlights}
                highlights={highlights.filter(h => h.when.date.format(compareDateFormat) === date.format(compareDateFormat))}
                onSelectSlot={onSelectSlot}
                onSelectEvent={onSelectEvent}
                onRenderEvents={onRenderEvents}
              />
            </div>
          );
        })}
      </div>
    </div>
  )
};

WeeklySlotCalendar.propTypes = {
  week: PropTypes.object,
  events: PropTypes.array,
  highlights: PropTypes.array,
  hasHighlights: PropTypes.bool,
  showYearNav: PropTypes.bool,
  onSelectWeek: PropTypes.func,
  onSelectSlot: PropTypes.func,
  onSelectEvent: PropTypes.func,
  onRenderEvents: PropTypes.func
};

export default WeeklySlotCalendar;

import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  bubbles: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%',
  },

  bubble: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: 2,
    fontSize: '0.8em',
    fontWeight: 700,
    margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
    padding: `${theme.spacing(0.75)} ${theme.spacing(2)}`,
    textTransform: 'uppercase',
  },

  selectable: {
    cursor: 'pointer'
  }
}));

/**
 * A component to display information in coloured 'bubbles'.
 *
 * @module Bubbles
 *
 * @param {IBubble[]} bubbles The bubbles to display
 * @param {function} onSelect A callback to execute when a bubble is selected
 * @param {any[]} selected An array of IDs indicating which bubbles have been selected
 *
 * @example
 * <Bubbles
 *   bubbles={[
 *     {id: 1, label: 'Bubble 1', colour: '#cc0000'},
 *     {id: 2, label: 'Bubble 2', colour: '#ffa458'},
 *     {id: 3, label: 'Bubble 3', colour: '#418412'}
 *   ]}
 *   onSelect={(selected) => console.log(selected)}
 *   selected={[1, 2]}
 * />
 *
 */
const Bubbles = ({bubbles, onSelect, selected = []}) => {
  const classes = useStyles();
  return bubbles ? (
    <ul className={classes.bubbles}>
      {bubbles.map((bubble, index) => {
        const isSelected = !onSelect || selected.indexOf(bubble.id) >= 0;
        return (
          <li
            key={index}
            className={`${classes.bubble} ${onSelect ? classes.selectable : ''}`}
            style={{
              backgroundColor: isSelected ? bubble.colour : '#fff',
              borderColor: bubble.colour,
              color: isSelected ? '#fff' : bubble.colour
            }}
            onClick={() => onSelect ? onSelect(bubble) : null}
          >
            {bubble.label}
          </li>
        )
      })}
    </ul>
  ) : null;
};

Bubbles.propTypes = {
  bubbles: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.array
};

export default Bubbles;

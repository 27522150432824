import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import React, {useEffect} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import routes from '../routes';
import {API} from '../services/API';
import {getUser} from '../store/actions/auth';
import {setOnline} from '../store/actions/general';
import Access from './access/Access';
import Admin from './admin/Admin';
import './App.scss';

const PRIMARY = '#0b70ad';
const SECONDARY = '#f7bc44'

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY,
      contrastText: '#fff'
    },
    secondary: {
      main: SECONDARY,
      contrastText: '#4a4a4a'
    },
    background: {
      default: '#fafafa',
    },
    auditStatus: {
      ongoing: {
        main: '#3ed3e7',
      },
      assigned: {
        main: PRIMARY
      },
      complete: {
        main: '#8abe49'
      },
      amends: {
        main: SECONDARY
      },
      approved: {
        main: '#578d33'
      },
      all: {
        main: '#111111'
      }
    }
  },
  typography: {
    fontFamily: [
      'Signika'
    ],
    body1: {
      fontSize: '0.875rem'
    }
  }
});

const Axios = ({children}) => {
  const store = useStore();

  useEffect(() => {
    API.initialise(store);
  }, [store]);
  return <>{children}</>;
};

const Online = ({children}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const off = () => dispatch(setOnline(false));
    window.addEventListener('offline', off);
    const on = () => dispatch(setOnline(true));
    window.addEventListener('online', on);
    dispatch(setOnline(window.navigator.onLine));
    return () => {
      window.removeEventListener('offline', off);
      window.removeEventListener('online', on);
    }
  }, [dispatch]);
  return children;
}

export default function App() {
  const user = useSelector(state => state['auth'].user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === false) {
      dispatch(getUser());
    }
  }, [user, dispatch]);

  let router = null;

  if (user !== false) {
    if (user) {
      router = (
        <Router>
          <Switch>
            <Route path="/auth"><Redirect to={`${routes.dashboard}`}/></Route>
            <Route path="/"><Admin/></Route>
          </Switch>
        </Router>
      );
    } else {
      router = (
        <Router>
          <Switch>
            <Route path="/auth"><Access/></Route>
            <Route path="/"><Redirect to={`${routes.login}`}/></Route>
          </Switch>
        </Router>
      )
    }
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Online>
          <Axios>
            {router}
          </Axios>
        </Online>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

import PropTypes from 'prop-types';
import React, {forwardRef, Fragment, useCallback, useEffect, useImperativeHandle} from 'react';
import {useFormContext} from 'react-hook-form';
import {useFormStyles} from './styles';
import {prefixWithSeparator} from './utilities';

/**
 * A component to hold panes for form fields for use with `BaseForm`.
 *
 * **Methods:**
 *
 * `reset(subject: object) : void` - Populate the fields from the supplied entity
 *
 * @module Panes
 *
 * @param {object} entity The source data being used
 * @param {string} prefix The prefix applied to the form data
 * @param {function} onReset A callback to handle resetting the form from source data
 * @param {IFormPane[]} panes An array of pane configurations
 * @param {string} title A title for the whole fieldset, if used will merge all fields into one pane
 *
 * @example
 * <Panes
 *   entity={{name: 'Name'}}
 *   prefix="person"
 *   onReset={(subject) => console.log(subject)}
 *   panes={[{
 *     title: 'Identifiers',
 *     fields: [
 *       <TextField
 *         name="Name"
 *         prefix="person
 *         label="Name"
 *       />
 *     ]}
 *   ]}
 * />
 *
 */
const Panes = forwardRef(({entity = null, prefix = '', onReset = null, panes, title}, ref) => {
  const classes = useFormStyles();
  const {reset} = useFormContext();

  const handleReset = useCallback((subject) => {
    if (subject && onReset) {
      subject = onReset(subject);
    }
    if (subject && prefix) {
      const attrs = {};
      for (let key of Object.keys(subject)) {
        attrs[`${prefixWithSeparator(prefix)}${key}`] = subject[key];
      }
      subject = attrs;
    }
    reset(subject);
  }, [onReset, reset, prefix]);

  useEffect(() => {
    if (entity) {
      handleReset({...entity});
    }
  }, [entity, handleReset]);

  useImperativeHandle(ref, () => ({
    reset(subject) {
      handleReset(subject);
    }
  }));

  let visiblePanes = panes;
  if (title) {
    let fields = [];
    for (let pane of panes) {
      fields = [...fields, ...pane.fields];
    }
    visiblePanes = [{title, fields}];
  }
  return (
    <div className={classes.panes}>
      {visiblePanes.map((pane, paneIndex) => (
        <div key={paneIndex} className={classes.pane}>
          {pane.title ? <h2 className={classes.paneTitle}><span>{pane.title}</span></h2> : null}
          {pane.fields.map((field, fieldIndex) => <Fragment key={`${paneIndex}-${fieldIndex}`}>{field}</Fragment>)}
        </div>
      ))}
    </div>
  );
});

Panes.propTypes = {
  entity: PropTypes.object,
  prefix: PropTypes.string,
  onReset: PropTypes.func,
  panes: PropTypes.array,
  title: PropTypes.string
};

export default Panes;

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef} from 'react';
import Spinner from './Spinner';

const useStyles = makeStyles(theme => {
  const border = `1px solid ${theme.palette.divider}`;
  const buttonSize = '60px';
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },

    nav: {
      borderBottom: border,
      display: 'flex',

      '& button': {
        alignItems: 'center',
        background: 'none',
        border: 0,
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        display: 'flex',
        height: buttonSize,
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        width: buttonSize,

        '&:focus': {
          outline: 0,
        }
      }
    },

    name: {
      alignItems: 'center',
      borderLeft: border,
      borderRight: border,
      display: 'flex',
      fontSize: '1.4em',
      fontWeight: 700,
      flex: 1,
      justifyContent: 'center',
      margin: 0,
      padding: 0,
      position: 'relative',
      textAlign: 'center',
    },

    spinner: {
      bottom: 0,
      margin: 'auto 0',
      position: 'absolute',
      right: theme.spacing(2),
      top: 0,
    }
  };
});

/**
 * A title bar with arrow controls to navigate through a list.
 *
 * @module BarSelector
 *
 * @param {IBarSelectorItem[]} list The items which a user can scroll through
 * @param {IBarSelectorItem} selected The item currently selected
 * @param {boolean} loading A boolean to determine whether the bar should show a loading spinner
 * @param {function} onChange A callback for when the selected item changes
 *
 * @example
 * <BarSelector
 *   list={[
 *     {title: 'Item 1', value: 1},
 *     {title: 'Item 2', value: 2},
 *     {title: 'Item 3', value: 3},
 *   ]}
 *   selected={{title: 'Item 2', value: 2}}
 *   loading={false}
 *   onChange={changed => console.log(changed)}
 * />
 *
 */
const BarSelector = ({list, selected, loading, onChange}) => {
  const classes = useStyles();
  const selectedIndex = useRef(0);

  useEffect(() => {
    if (list.length > 0 && selected) {
      selectedIndex.current = list.findIndex(i => i.value === selected.value);
    }
  }, [list, selected]);


  const handleBack = useCallback((e) => {
    e.preventDefault();
    let index = selectedIndex.current;
    if (index > 0) {
      index--;
    } else {
      index = list.length - 1;
    }
    selectedIndex.current = index;
    onChange(list[index]);
  }, [list, onChange]);

  const handleNext = useCallback((e) => {
    e.preventDefault();
    let index = selectedIndex.current;
    if (index < list.length - 1) {
      index++;
    } else {
      index = 0;
    }
    selectedIndex.current = index;
    onChange(list[index]);
  }, [list, onChange]);

  return selected ? (
    <div className={classes.container}>
      <div className={classes.nav}>
        <button onClick={handleBack}><ChevronLeftIcon/></button>
        <h2 className={classes.name}>{selected.title}{loading ? <Spinner className={classes.spinner}/> : null}</h2>
        <button onClick={handleNext}><ChevronRightIcon/></button>
      </div>
    </div>
  ) : null;
};

BarSelector.propTypes = {
  list: PropTypes.array,
  selected: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func
};

export default BarSelector;

import {useCallback, useState} from 'react';

/**
 * A hook to manage a selection of named errors
 *
 * @function useErrors
 *
 * @param {object} messages An object where keys determine error name and values are error messages
 *
 * @returns {{errors: object, toggleError: function}} `{errors: object, toggleError: function}`
 *
 * `errors` - An object which contains the error message for errors which are showing and blank strings for those which
 * aren't.
 *
 * `toggleError` - A function which takes the name of an error along with a boolean to mark whether it should be
 * showing.
 *
 * @example
 * const {errors, toggleError} = useErrors({error1: 'Error 1', error2: 'Error 2'});
 * console.log(errors); // Output {error1: '', error2: ''}
 * toggleError('error1', true);
 * console.log(errors); // Output {error1: 'Error 1', error2: ''}
 * toggleError('error2', true);
 * console.log(errors); // Output {error1: 'Error 1', error2: 'Error 2'}
 *
 */
export function useErrors(messages) {
  const [errors, setErrors] = useState(
    Object.keys(messages).reduce((obj, key) => ({...obj, [key]: ''}), {})
  );

  const toggleError = useCallback((error, show) => {
    let state;
    if (show) {
      state = Object.keys(messages).reduce((obj, key) => ({...obj, [key]: error === key ? messages[key] : ''}), {});
    } else {
      state = Object.keys(messages).reduce((obj, key) => ({...obj, [key]: ''}), {});
    }
    setErrors(state);
  }, [messages]);

  return {errors, toggleError};
}

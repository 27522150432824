import {dateTime, name} from '@management-ui/core';
import {Button, Paper, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useRef, useState} from 'react';
import Text from '../../../../components/Text';
import NoteForm from '../../forms/NoteForm';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginTop: theme.spacing(2),
    width: '100%',
  },

  column: {
    flex: 1,

    '& .MuiTypography-h6': {
      color: theme.palette.primary.main
    },

    '&:first-of-type': {
      marginRight: theme.spacing(1)
    },

    '&:last-of-type': {
      marginLeft: theme.spacing(1)
    }
  },

  form: {
    padding: theme.spacing(2),
  },

  noNotes: {
    padding: theme.spacing(2),
  },

  note: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),

    '& .MuiTypography-root': {
      padding: theme.spacing(2),
    },

    '& .MuiTypography-subtitle1': {
      flex: '0 0 100%',
      width: '100%'
    },

    '& .MuiTypography-subtitle2': {
      color: theme.palette.primary.main,
      flex: '0 0 50%',
      width: '50%',

      '&:last-of-type': {
        textAlign: 'right',
      }
    }
  }
}));

const Notes = ({audit, onAuditUpdated}) => {
  const classes = useStyles();
  /** @type {({current: NoteForm})} */
  const formRef = useRef();
  const [note, setNote] = useState({});

  const handleSaved = useCallback((saved) => {
    setNote({});
    onAuditUpdated(saved);
  }, [onAuditUpdated]);

  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <Paper className={classes.form}>
          <Typography variant="h6">Add a New Note</Typography>
          <NoteForm ref={formRef} audit={audit} note={note} onSaved={handleSaved}/>
          <Button variant="contained" onClick={() => formRef.current.save()}>Save</Button>
        </Paper>
      </div>
      <div className={classes.column}>
        {audit.notes.length < 1 ? (
          <Typography className={classes.noNotes}>There are currently no notes for this audit</Typography>
        ) : (
          audit.notes.map((note, index) => (
            <Paper key={index} className={classes.note}>
              <Typography variant="subtitle1"><Text text={note.comment}/></Typography>
              <Typography variant="subtitle2">{name(note.user)}</Typography>
              <Typography variant="subtitle2">{dateTime(note.created_at)}</Typography>
            </Paper>
          ))
        )}
      </div>
    </div>
  );
};

export default Notes;

import {Box, styled, Typography} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Table from '../Audits/Table';
import Summary from './Summary';
import Surface from './Surface';

const Auditor = styled('div')(({theme}) => ({
  borderBottom: `4px solid ${theme.palette.grey['100']}`,
  display: 'flex',

  '&:last-of-type': {
    borderBottom: 0,
  },

  '& > h2': {
    flex: 1,
    fontSize: '1.2em',
    padding: theme.spacing(1),

    '& > a': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    }
  },

  '& > div': {
    flex: 3,
  }
}));

const Admin = ({onLoading}) => {
  const services = useContext(ServiceContext);
  const [summary, setSummary] = useState(null);
  const [auditors, setAuditors] = useState([]);
  const {online} = useSelector(state => state['general']);

  useEffect(() => {
    if (online) {
      onLoading(true);
      services.audit.getSummary().then((retrievedSummary) => {
        setSummary(retrievedSummary);
        services.user.getAuditors().then((retrievedUsers) => {
          setAuditors(retrievedUsers);
          onLoading(false);
        }).catch(() => onLoading(false));
      }).catch(() => onLoading(false));
    }
  }, [services, online, onLoading]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {online ? (
        <>
          <Surface>
            <Typography variant="h6">Ongoing Audits</Typography>
            {summary ? <Summary summary={summary}/> : null}
          </Surface>
          <Surface>
            <Typography variant="h6">Auditors</Typography>
            {auditors.map(({id, first_name: firstName, last_name: lastName, audit_summary: auditSummary}, index) => (
              <Auditor key={index}>
                <h2><Link to={reverse(routes.users.detail, {id})}>{firstName} {lastName}</Link></h2>
                <Summary summary={auditSummary} condensed={true}/>
              </Auditor>
            ))}
          </Surface>
        </>
      ) : null}
      <Box marginTop={2}>
        <Table/>
      </Box>
    </Box>
  );
}

export default Admin;

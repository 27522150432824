import {prefixWithSeparator, RelationAutocomplete} from '@management-ui/core';
import React, {useCallback, useContext} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../components/Services';

const ArloOrganisationLookup = ({nameField, idField, prefix}) => {
  const services = useContext(ServiceContext);
  const {/** @var {function(string): string} */getValues} = useFormContext();

  const handleValidate = useCallback(() => {
    const fieldPrefix = prefixWithSeparator(prefix);
    if (!getValues(`${fieldPrefix}${nameField}`) && !getValues(`${fieldPrefix}${idField}`)) {
      return 'Please select an organisation';
    }
    return true;
  }, [getValues, nameField, idField, prefix]);

  const handleLoad = useCallback(query => (
    services.arlo.searchOrganisations(query)
      .then(response => response.map(organisation => ({title: organisation.name, value: organisation.arlo_id})))
      .catch(() => [])
  ), [services]);

  return (
    <RelationAutocomplete
      valueName={idField}
      titleName={nameField}
      prefix={prefix}
      label="Look-up Arlo Organisation"
      rules={{validate: handleValidate}}
      loadOptions={handleLoad}
    />
  )
};

export default ArloOrganisationLookup;

import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const AnswerForm = forwardRef(({mode, audit, section, question, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  const handleSave = useCallback(({rating, evidence, lead, action, impact, timescale, progress}) => (
    services.audit.saveQuestion(audit, section, {
      id: question.id,
      evidence,
      lead,
      action,
      impact,
      timescale,
      progress,
      ...(mode === 'requiredFile' ? {
        rating: evidence ? 3 : 1
      } : {
        rating,
      })
    })
  ), [services, mode, audit, section, question]);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={question}
      type="question"
      fieldsComponent={(props, onLoading) => (
        <Fields
          mode={mode}
          audit={audit}
          onSaved={(saved) => onSaved(saved, false)}
          onLoading={onLoading}
          {...props}
        />
      )}
      onSave={handleSave}
      onSaved={(saved) => onSaved(saved, true)}
    />
  );
});

export default AnswerForm;

import {Box, Grid} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A component for showing child content in even columns
 *
 * @module DetailColumns
 *
 * @param {node[]} columns An array of child elements to display
 *
 * @example
 * <DetailColumns
 *   columns={[
 *     <h2>Column A</h2>,
 *     <h2>Column B</h2>,
 *     <h2>Column C</h2>,
 *     <h2>Column D</h2>
 *   ]}
 * />
 *
 */
const DetailColumns = ({columns}) => {
  return (
    <Box marginTop={2}>
      <Grid container spacing={2}>
        {columns.map((column, index) => (
          <Grid key={index} item xs={12 / columns.length} display="flex" component={Box}>
            {column}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

DetailColumns.propTypes = {
  columns: PropTypes.array
};

export default DetailColumns;

import {styled} from '@mui/material';
import React from 'react';
import {useAuditStatuses} from '../../../../hooks';

const Container = styled('div')(({theme}) => ({
  alignItems: 'center',
  borderBottom: `2px solid ${theme.palette.grey['100']}`,
  borderTop: `2px solid ${theme.palette.grey['100']}`,
  display: 'flex',
  flex: 1,
  padding: `0 ${theme.spacing(2)}`,
  width: '100%',

  '& > span': {
    flex: 0,
    paddingRight: theme.spacing(2),
  }
}));

const Statuses = styled('ul')(({theme}) => ({
  display: 'flex',
  flex: 1,
  listStyle: 'none',
  margin: 0,
  padding: 0,
}));

const Status = styled('li')(({theme}) => ({
  borderLeft: `2px solid ${theme.palette.grey['100']}`,
  color: 'var(--status-colour)',
  cursor: 'pointer',
  fontWeight: '700',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

  '&:last-of-type': {
    borderRight: `2px solid ${theme.palette.grey['100']}`,
  },

  '&.selected': {
    background: 'var(--status-colour)',
    color: theme.palette.common.white,
  }
}));

const Filter = ({status, onChange}) => {
  const statuses = useAuditStatuses();
  return (
    <Container>
      <span>Displaying:</span>
      <Statuses>
        {statuses.map(({title, key, colour}, index) => (
          <Status
            key={index}
            className={status === key ? 'selected' : ''}
            style={{'--status-colour': colour}}
            onClick={() => status === key ? null : onChange(key)}
          >
            {title}
          </Status>
        ))}
      </Statuses>
    </Container>
  );
}

export default Filter;

import {useTheme} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

export const usePermissions = (permissions = null) => {
  const user = useSelector(state => state['auth'].user);
  const [hasPermission, setHasPermission] = useState(false);

  const userCan = useCallback((permissions, can = null, cant = null) => {
    for (let permission of permissions) {
      if (user.permissions[`can_${permission}`]) {
        return can;
      }
    }
    return cant;
  }, [user]);

  useEffect(() => {
    if (permissions) {
      setHasPermission(userCan(permissions, true, false));
    } else {
      setHasPermission(false);
    }
  }, [permissions, user, userCan])

  return {hasPermission, user, userCan}
}

export const useAuditStatuses = () => {
  const theme = useTheme();
  return useMemo(() => [
    {title: 'Ongoing', key: 'ongoing', colour: theme.palette['auditStatus'].ongoing.main},
    {title: 'Assigned', key: 'assigned',  colour: theme.palette['auditStatus'].assigned.main},
    {title: 'Complete', key: 'complete', colour: theme.palette['auditStatus'].complete.main},
    {title: 'Requires Amends', key: 'incomplete', colour: theme.palette['auditStatus'].amends.main},
    {title: 'Approved', key: 'approved', colour: theme.palette['auditStatus'].approved.main},
    {title: 'All', key: 'all', colour: theme.palette['auditStatus'].all.main},
  ], [theme]);
}

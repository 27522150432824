import {Alert, Button, LinearProgress, Link, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

/**
 * A component to allow users to request a password reset.
 *
 * @module ForgotPassword
 *
 * @param {IAccessRoutes} routes The routes used within the app for access functions
 * @param {function} onForgotPassword A callback to execute when requesting password reset
 *
 * @example
 * <ForgotPassword
 *   routes={{login: '/login'}}
 *   onForgotPassword={(email) => new Promise(resolve => console.log({email}) || resolve()}
 * />
 *
 */
const ForgotPassword = ({routes, onForgotPassword}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  let loadingBar = null;
  if (loading) {
    loadingBar = <LinearProgress className={classes.progress}/>;
  }

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    setEmailError('');
    if (!email) {
      setEmailError('Please enter your email address');
    } else {
      setLoading(true);
      const complete = () => {
        setEmail('');
        setLoading(false);
        setDone(true);
      }
      onForgotPassword(email).then(complete).catch(complete);
    }
  }, [onForgotPassword, email]);

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      {done ? <Alert severity="success">You have been sent an email with a link to reset your password</Alert> : null}
      <TextField
        name="email"
        id="email"
        label="Email Address"
        error={!!emailError}
        helperText={emailError ?? ''}
        value={email}
        onChange={event => setEmail(event.target.value)}
        autoComplete="email"
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={handleSubmit}
      >
        Reset
      </Button>
      <Link component={RouterLink} to={`${routes.login}`} variant="body2">
        Log-in
      </Link>
      {loadingBar}
    </form>
  );
};

ForgotPassword.propTypes = {
  routes: PropTypes.object,
  onForgotPassword: PropTypes.func
};

export default ForgotPassword;

import {API} from '../API';

export class QuestionService {

  async reorderQuestions(type, section, questions) {
    return (await API.getConnection())
      .put(`audit-types/${type.id}/sections/${section.id}/questions`, {questions})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveQuestion(type, section, data) {
    let method = 'post';
    let url = `audit-types/${type.id}/sections/${section.id}/questions`;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async attachQuestion(type, section, questionID) {
    return (await API.getConnection())
      .post(`audit-types/${type.id}/sections/${section.id}/questions/attach`, {question_id: questionID})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async copyQuestion(type, section, question) {
    return (await API.getConnection())
      .post(`audit-types/${type.id}/sections/${section.id}/questions/${question.id}/copy`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async detachQuestion(type, section, question) {
    return (await API.getConnection())
      .delete(`audit-types/${type.id}/sections/${section.id}/questions/${question.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}

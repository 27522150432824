import PropTypes from 'prop-types';
import {forwardRef, useImperativeHandle} from 'react';

/**
 * A simple wrapper component to provide a `save()` handle.
 *
 * @module FormWrapper
 *
 * @param {function} onSave A callback to be run when the form is saved.
 * @param {Array<node>} children Child notes to be rendered within the wrapper.
 *
 * @example
 * <FormWrapper onSave={() => saveForm()}>
 *   // Form Components
 * </FormWrapper>
 *
 */
const FormWrapper = forwardRef(({onSave, children}, ref) => {
  useImperativeHandle(ref, () => ({
    save() {
      onSave();
    }
  }));
  return children;
});

FormWrapper.propTypes = {
  onSave: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default FormWrapper;

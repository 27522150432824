import {RadioGroup} from '@management-ui/core';
import {styled} from '@mui/material';
import React, {useMemo} from 'react';
import Text from '../../../components/Text';
import {FULLY_MET, NOT_MET, PARTLY_MET} from '../../../constants';

const Rating = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.grey['100'],
  display: 'flex',
  flexDirection: 'column',
  float: 'left',
  margin: `${theme.spacing(0.5)} 0`,
  padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
  width: '100%',

  '& > h3': {
    margin: 0,
    padding: `0 0 ${theme.spacing(0.5)} 0`,
  },

  '& > p': {
    margin: 0,
    padding: `${theme.spacing(0.5)} 0`,

    '&:last-of-type': {
      paddingBottom: 0,
    }
  },

  '&.rating1 > h3': {
    color: NOT_MET,
  },

  '&.rating2 > h3': {
    color: PARTLY_MET,
  },

  '&.rating3 > h3': {
    color: FULLY_MET,
  }
}));

const RatingField = ({prefix, question}) => {
  const options = useMemo(() => {
    return [
      {
        title: (
          <Rating className="rating1">
            <h3>Not Met</h3>
            <p>{question.not_met ? <Text text={question.not_met}/> : '-'}</p>
          </Rating>
        ), value: '1'
      },
      {
        title: (
          <Rating className="rating2">
            <h3>Partly Met</h3>
            <p>{question.partly_met ? <Text text={question.partly_met}/> : '-'}</p>
          </Rating>
        ), value: '2'
      },
      {
        title: (
          <Rating className="rating3">
            <h3>Fully Met</h3>
            <p>{question.fully_met ? <Text text={question.fully_met}/> : '-'}</p>
          </Rating>
        ), value: '3'
      },
    ]
  }, [question]);


  return (
    <RadioGroup
      name="rating"
      prefix={prefix}
      label="Rating"
      options={options}
    />
  );
};

export default RatingField;

import {ErrorPopup, useErrors} from '@management-ui/core';
import {Checkbox, Grid, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {ServiceContext} from '../../../../components/Services';

const useStyles = makeStyles(() => ({
  saving: {
    opacity: 0.4,
    pointerEvents: 'none'
  },

  label: {
    cursor: 'pointer',
  }
}));

const PERMISSIONS = [
  {title: 'Can Manage Categories?', key: 'can_manage_audit_types'},
  {title: 'Can Manage Audits?', key: 'can_manage_audits'},
  {title: 'Can Assign Audits?', key: 'can_assign_audits'},
  {title: 'Can Moderate Audits?', key: 'can_moderate_audits'},
  {title: 'Can Manage Trusts?', key: 'can_manage_trusts'},
  {title: 'Can Manage Users?', key: 'can_manage_users'},
  {title: 'Can Change Settings?', key: 'can_change_settings'},
  {title: 'Can Access Archive?', key: 'can_access_archive'},
];

const Permissions = ({user, onUpdated}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const {user: currentUser} = useSelector(state => state['auth']);
  const [saving, setSaving] = useState(false);
  const {errors, toggleError} = useErrors(useMemo(() => ({
    manageUsers: 'You can\'t disable this permission for yourself as you will get locked out',
  }), []));

  const handleUpdate = useCallback((key) => {
    if (key === 'can_manage_users' && user.id === currentUser.id) {
      toggleError('manageUsers', true);
    } else {
      setSaving(true);
      const update = {id: user.id};
      update[key] = !user.permissions[key];
      services.user.saveUser(update).then((saved) => {
        setSaving(false);
        onUpdated(saved);
      }).catch(() => setSaving(false));
    }
  }, [services, currentUser, user, onUpdated, toggleError]);

  return (
    <>
      <Grid padding={1} className={saving ? classes.saving : ''} container spacing={2}>
        {PERMISSIONS.map(({title, key}, index) => (
          <Grid item xs={4} key={index} display="flex" alignItems="center">
            <Checkbox checked={user.permissions[key]} onChange={() => handleUpdate(key)}/>
            <Typography className={classes.label} onClick={() => handleUpdate(key)}>{title}</Typography>
          </Grid>
        ))}
      </Grid>
      <ErrorPopup errors={['manageUsers']} messages={errors}/>
    </>
  )
};

export default Permissions;

import {Alert, Snackbar} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

/**
 * A component to display errors, designed to work with the `useErrors` hook.
 *
 * @module ErrorPopup
 *
 * @param {string[]} errors An array of strings indicating which errors the popup is responsible for.
 * @param {object} messages An object containing the error message for errors which are showing and blank strings for
 * those which aren't.
 *
 * @example
 * <ErrorPopup
 *   errors={['invalid', 'missing']}
 *   messages={{invalid: '', missing: 'This is missing}}
 * />
 *
 */
const ErrorPopup = ({errors, messages}) => {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const showing = Object.keys(messages).filter(e => errors.indexOf(e) >= 0 && !!messages[e]);
    if (showing.length > 0) {
      setMessage(messages[showing[0]]);
      setOpen(true);
    }
  }, [errors, messages]);

  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={() => setOpen(false)}>
      <Alert onClose={() => setOpen(false)} severity="error">{message}</Alert>
    </Snackbar>
  );
};

ErrorPopup.propTypes = {
  errors: PropTypes.array,
  messages: PropTypes.object
};

export default ErrorPopup;

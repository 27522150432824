import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import Table from './Table';

const Schools = () => (
  <div>
    <Breadcrumbs crumbs={[{title: 'Schools'}]}/>
    <Paper>
      <Table/>
    </Paper>
  </div>
);

export default Schools;

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as MatRadioGroup
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useFormStyles} from './styles';
import {prefixWithSeparator} from './utilities';

/**
 * A radio set for use within the `BaseForm` component.
 *
 * @module RadioGroup
 *
 * @param {string} name The name of the field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {ISelectOption[]} options The available options to show as radio buttons
 * @param {object} fieldProps Any additional props for the Material UI [RadioGroup](https://mui.com/components/radio-buttons/#radio-group)
 *
 * @example
 * <RadioGroup
 *   name="title"
 *   prefix={prefix}
 *   label="Title"
 *   id="title"
 *   rules={{required: 'Please select a title'}}
 *   options={[{title: 'Mr', value: 'mr'}, {title: 'Mrs', value: 'mrs'}]}
 * />
 *
 */
const RadioGroup = (
  {
    name,
    prefix = '',
    label,
    id = null,
    rules = {},
    options = [],
    fieldProps
  }
) => {
  const classes = useFormStyles();
  const {control, formState: {errors}} = useFormContext();
  const prefixedName = useMemo(() => `${prefixWithSeparator(prefix)}${name}`, [name, prefix]);
  return options.length ? (
    <FormControl
      className={classes.radioGroup}
      variant="outlined"
      component="fieldset"
      required={rules && !!rules.required}
      fullWidth>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={prefixedName}
        control={control}
        rules={rules}
        render={({field}) => (
          <MatRadioGroup
            id={id ?? prefixedName}
            {...fieldProps}
            {...field}
            value={`${field.value ?? ''}`}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={`${option.value}`}
                label={option.title}
                control={<Radio/>}
              />
            ))}
          </MatRadioGroup>
        )}
      />
      {!!errors[prefixedName] ? <FormHelperText>{errors[prefixedName].message}</FormHelperText> : null}
    </FormControl>
  ) : null;
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object,
  options: PropTypes.array,
  fieldProps: PropTypes.object
};

export default RadioGroup;

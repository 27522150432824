import {ConfirmationDialog, DataTable} from '@management-ui/core';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const [selected, setSelected] = useState(null);
  const [showRestore, setShowRestore] = useState(false);

  const handleSelect = useCallback((type) => {
    if (!archive) {
      history.push(routes.questions.index);
    } else {
      setSelected(type);
      setShowRestore(true);
    }
  }, [history, archive]);

  const handleRestore = useCallback((confirmed) => {
    setShowRestore(false);
    if (confirmed) {
      services.question.restoreQuestion(selected).then(() => {
        if (tableRef.current) {
          tableRef.current.refresh();
        }
      }).catch(() => null);
    }
  }, [services, selected])

  return (
    <>
      <DataTable
        ref={tableRef}
        title="Questions"
        columns={useMemo(() => [
          {title: 'Body', field: 'body'}
        ], [])}
        loadData={useCallback(query => new Promise((resolve, reject) => {
          services.question.getTableQuestions(query, archive ? {archive: 'only'} : {})
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(() => {
            reject();
          });
        }), [services, archive])}
        onRowClick={handleSelect}
      />
      <ConfirmationDialog
        title="Restore Question"
        message="Are you sure you want to restore this question?"
        open={showRestore}
        onClose={handleRestore}
      />
    </>
  );
};

export default Table;

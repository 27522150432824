const routes = {
  // Access
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',

  // Admin
  dashboard: '/',

  audits: {
    index: '/audits',
    detail: '/audits/:id',
    tab: '/audits/:id/:tab',
    section: '/audits/:id/:tab/:sectionID'
  },

  questions: {
    index: '/questions'
  },

  trusts: {
    index: '/trusts',
    detail: '/trusts/:id',
    school: '/trusts/:trustID/schools/:id',
  },

  schools: {
    index: '/schools',
    detail: '/schools/:id',
  },

  auditTypes: {
    index: '/categories',
    detail: '/categories/:id',
    section: '/categories/:id/sections/:sectionID',
  },

  users: {
    index: '/users',
    detail: '/users/:id',
  },

  management: '/management/:tab?',

  archive: {
    index: '/archive/:tab?',
  }
};

export default routes;

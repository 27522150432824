import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const DateForm = forwardRef(({entity, sectionTitle, fieldTitle, onSave, onSaved}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={entity}
      type="date"
      fieldsComponent={(props) => <Fields {...props} entity={entity} sectionTitle={sectionTitle} fieldTitle={fieldTitle}/>}
      onSave={onSave}
      onSaved={onSaved}
    />
  );
});

export default DateForm;

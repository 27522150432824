import SaveIcon from '@mui/icons-material/Save';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useErrors} from '../hooks/errors';
import {useFloatingButtons} from '../hooks/floating';
import ErrorPopup from './ErrorPopup';
import FloatingButtons from './FloatingButtons';

/**
 * An interface for editing a single element with floating buttons.
 *
 * @module Settings
 *
 * @param {function} getForm A callback to return the form to be displayed.
 * @param {function} loadSettings A function which returns a promise containing the associated data.
 *
 * @example
 * <Settings
 *   getForm={(ref, settings, onSaved, onError) => (
 *     <SettingsForm ref={ref} settings={settings} onSaved={onSaved} onError={onError} />
 *   )}
 *   loadSettings={() => new Promise(resolve => resolve({settingA: 'A', settingB: 'B'})}
 * />
 *
 */
const Settings = ({getForm, loadSettings}) => {
  const formRef = useRef(null);
  const [settings, setSettings] = useState({});
  const {buttons, setButtonState} = useFloatingButtons(useMemo(() => ({
    save: {
      colour: 'secondary',
      states: {
        default: {
          label: 'Save',
          icon: SaveIcon,
          spinning: false
        },
        saving: {
          label: 'Saving...',
          spinning: true
        },
        loading: {
          label: 'Loading...',
          spinning: true
        }
      }
    }
  }), []));

  const {errors, toggleError} = useErrors(useMemo(() => ({
    access: 'Sorry the settings could not be accessed',
    save: 'Sorry the settings could not be saved'
  }), []));

  useEffect(() => {
    setButtonState('save', 'loading');
    loadSettings().then(retrieved => {
      setSettings(retrieved);
      setButtonState('save', 'default');
    }).catch(() => {
      toggleError('access', true);
      setButtonState('save', 'default');
    });
  }, [loadSettings, toggleError, setButtonState]);

  const handleSave = useCallback(() => {
    setButtonState('save', 'saving');
    formRef.current.save();
  }, [setButtonState]);

  const handleSaved = useCallback((saved) => {
    setSettings(saved);
    setButtonState('save', 'default');
  }, [setButtonState]);

  const handleError = useCallback(() => {
    setButtonState('save', 'default');
    toggleError('save', true);
  }, [setButtonState, toggleError]);

  return (
    <Box p={2}>
      {getForm(formRef, settings, handleSaved, handleError)}
      <FloatingButtons buttons={buttons} onClick={handleSave}/>
      <ErrorPopup errors={['access', 'save']} messages={errors}/>
    </Box>
  );
};

Settings.propTypes = {
  getForm: PropTypes.func,
  loadSettings: PropTypes.func
};

export default Settings;

import {styled} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FULLY_MET, NOT_MET, PARTLY_MET} from '../../../../constants';
import ProgressBar from './ProgressBar';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const Title = styled('h3')(({theme}) => ({
  fontSize: '1em',
  margin: 0,
  padding: `0 0 ${theme.spacing(1)}`,
  textAlign: 'center'
}));

const AuditTableProgressBar = ({title, summary}) => {
  const [progress, setProgress] = useState({counts: [], total: 0});

  useEffect(() => {
    let total = 0;
    let counts = [];
    if (summary) {
      const none = summary.no_rating ?? 0;
      const not = summary.not_met ?? 0;
      const partly = summary.partly_met ?? 0;
      const fully = summary.fully_met ?? 0;
      total = none + not + partly + fully;
      counts = [
        {
          title: 'No Rating',
          colour: null,
          count: none,
        },
        {
          title: 'Not Met',
          colour: NOT_MET,
          count: not,
        },
        {
          title: 'Partly Met',
          colour: PARTLY_MET,
          count: partly,
        },
        {
          title: 'Fully Met',
          colour: FULLY_MET,
          count: fully
        },
      ];
    }
    setProgress({total, counts});
  }, [summary]);

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <ProgressBar {...progress} onSelect={() => null} condensed={true}/>
    </Container>
  );
};

export default AuditTableProgressBar;

import {ConfirmationDialog, ReorderingPanel, Title} from '@management-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Paper} from '@mui/material';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import ManageQuestion from './ManageQuestion';
import {Archive} from '@mui/icons-material';

const Questions = () => {
  const services = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState({});

  const formatQuestions = useCallback((list) => (
    list.map((question) => ({
      entity: question,
      attributes: [question.is_header ? question.body.toUpperCase() : question.body],
      highlight: question.is_header
    }))
  ), []);

  const loadQuestions = useCallback(() => {
    setLoading(true);
    services.question.getQuestions().then(retrieved => {
      setLoading(false);
      setQuestions(formatQuestions(retrieved));
    }).catch(() => setLoading(false));
  }, [services, formatQuestions]);

  useEffect(() => {
    loadQuestions();
  }, [loadQuestions]);

  const handleReorder = useCallback((reordered) => {
    setQuestions(formatQuestions(reordered));
    setLoading(true);
    return services.question.reorderQuestions(reordered.map((question, index) => ({
      id: question.id,
      index: index + 1
    }))).then(() => setLoading(false)).then(() => setLoading(false));
  }, [services, formatQuestions, setLoading]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.question.deleteQuestion(selected).then(() => {
        setLoading(false);
        loadQuestions();
      }).catch(() => setLoading(false));
    }
  }, [services, selected, loadQuestions])

  return (
    <>
      <Title title="Questions" loading={loading}/>
      <Paper component={Box} marginTop={2} overflow="hidden">
        <ReorderingPanel
          title="Manage Questions"
          items={questions}
          controls={[
            {
              title: 'Edit Question',
              icon: <EditIcon/>,
              onClick: (question) => setSelected(question) || setShowForm(true)
            },
            {
              title: 'Archive Question',
              icon: <Archive/>,
              onClick: (question) => setSelected(question) || setShowDelete(true)
            }
          ]}
          onNew={() => setSelected(null) || setShowForm(true)}
          onReorder={handleReorder}
        />
      </Paper>
      <ManageQuestion
        open={showForm}
        maxWidth="md"
        question={selected}
        onClose={() => setShowForm(false)}
        onSaved={() => setShowForm(false) || loadQuestions()}
      />
      <ConfirmationDialog
        title="Archive Question"
        message="Are you sure you want to archive this question? It can be later restored if required"
        open={showDelete}
        onClose={handleDelete}
      />
    </>
  );
}

export default Questions;

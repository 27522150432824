import {Title} from '@management-ui/core';
import * as React from 'react';
import {useCallback, useRef, useState} from 'react';
import {usePermissions} from '../../../../hooks';
import Admin from './Admin';
import Auditor from './Auditor';

const Dashboard = () => {
  const {userCan} = usePermissions();
  const [loading, setLoading] = useState(false);
  const loadingCount = useRef(0);

  const handleLoading = useCallback((isLoading) => {
    if (isLoading) {
      loadingCount.current++;
    } else {
      loadingCount.current--;
    }
    setLoading(loadingCount.current > 0);
  }, []);

  return (
    <div>
      <Title title="Dashboard" loading={loading}/>
      {userCan(
        ['assign_audits'],
        <Admin onLoading={handleLoading}/>,
        userCan(
          ['manage_audits'],
          <Auditor onLoading={handleLoading}/>,
          null
        )
      )}
    </div>
  );
};

export default Dashboard;

import {useCallback, useEffect, useRef, useState} from 'react';

/**
 * A hook to manage floating buttons
 *
 * @function useFloatingButtons
 *
 * @param {object} config An object to configure the floating buttons
 *
 * @returns {{buttons: IFloatingButton[], setButtonState: function}} `{buttons: IFloatingButton[], setButtonState: function}`
 *
 * `buttons` - An array which contains buttons configured with their correct state.
 *
 * `setButtonState` - A function which takes the name of button along the state it should be set to.
 *
 * @example
 * const {buttons, setButtonState} = useFloatingButtons(useMemo(() => ({
 *   save: {
 *     colour: 'secondary',
 *     states: {
 *       default: {
 *         label: 'Save',
 *         icon: SaveIcon,
 *         spinning: false
 *       },
 *       saving: {
 *         label: 'Saving...',
 *         spinning: true
 *       }
 *     }
 *   }
 * }), []));
 * console.log(buttons); // Output [{name: 'save', label: 'Save', icon: SaveIcon, spinning: false, colour: 'secondary'}]
 * setButtonState('save', 'saving');
 * console.log(buttons); // Output [{name: 'save', label: 'Saving...', icon: null, spinning: true, colour: 'secondary'}]
 * setButtonState('save', 'default');
 * console.log(buttons); // Output [{name: 'save', label: 'Save', icon: SaveIcon, spinning: false, colour: 'secondary'}]
 */
export function useFloatingButtons(config = {}) {
  const currentState = useRef([]);

  const configureButton = useCallback((button, state = '') => {
    if (!state) {
      state = Object.keys(config[button]['states'])[0];
    }
    return config.hasOwnProperty(button) ? {
      name: button,
      label: config[button]['states'][state].label,
      icon: config[button]['states'][state].icon,
      spinning: !!config[button]['states'][state].spinning,
      colour: config[button].colour
    } : null;
  }, [config]);

  const [buttons, setButtons] = useState(Object.keys(config).map(button => configureButton(button)));

  useEffect(() => {
    currentState.current = buttons;
  }, [buttons]);

  const setButtonState = useCallback((button, state) => {
    const updated = [...currentState.current];
    const index = updated.findIndex(b => b.name === button);
    if (index >= 0) {
      updated[index] = configureButton(button, state);
      setButtons(updated);
    }
  }, [configureButton]);

  return {buttons, setButtonState};
}

import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {Archive} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import TrustForm from '../../forms/TrustForm';
import Schools from '../Schools/Table';
import Wrapper from './Wrapper';

const Trust = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({trust: 'Sorry the trust could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'delete']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [trust, setTrust] = useState(null);

  const loadTrust = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.trust.getTrust(id).then(retrieved => {
        setTrust(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('trust', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadTrust(match.params?.id);
    }
  }, [history, match, trust, loadTrust]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (trust) {
      header = trust.name;
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [trust]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setTrust(updated);
    } else if (trust?.id) {
      loadTrust(trust.id);
    }
  }, [toggleDialog, setTrust, loadTrust, trust]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.trust.deleteTrust(trust).then(() => {
        setLoading(false);
        history.push(routes.trusts.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, trust, toggleDialog]);

  return (
    <Wrapper title={title} loading={loading} crumbs={crumbs} errors={errors}>
      {trust?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Trust Details"
              actions={[
                {title: 'Edit Trust', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {
                  title: 'Archive Trust',
                  icon: <Archive/>,
                  onClick: () => toggleDialog('delete', true)
                }
              ]}
              details={[
                {title: 'Name', value: trust.name},
                {title: 'View on Arlo', value: 'Click here', link: trust.arlo.link}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Trust"
                    open={openDialogs['edit'] ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <TrustForm
                        {...props}
                        trust={trust}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Archive Trust"
                    message="Are you sure you want to archive this trust? This can be restored later if needed."
                    open={openDialogs['delete']}
                    onClose={handleDelete}
                  />
                ),
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(trust.created_at)},
                {title: 'Last Updated', value: dateTime(trust.updated_at)}
              ]}
            />,
          ]}/>

          <DetailColumns columns={[
            <DetailPane title="Schools" collapseChildrenHeight={false}>
              <Schools trust={trust}/>
            </DetailPane>
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Trust;

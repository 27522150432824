// noinspection JSUnusedGlobalSymbols

import moment from './moment';

/**
 * Add all name elements into one string
 *
 * @function name
 *
 * @param {object} entity An entity with 1 or more name fields
 * @param {string[]} fields An array of name fields (default: `title`, `first_name`, `last_name`)
 *
 * @returns {string}
 *
 * @example
 * console.log(name({title: 'Mr', first_name: 'Joe', last_name: 'Blogs'});
 * // Output 'Mr Joe Blogs'
 *
 */
export function name(entity, fields = ['title', 'first_name', 'last_name']) {
  return entity ? fields
    .map(n => entity[n])
    .filter(n => !!n)
    .join(' ') : '';
}

/**
 * Add all address populated elements into an array
 *
 * @function address
 *
 * @param {object} entity An entity with 1 or more address fields
 * @param {string[]} fields An array of name fields (default: `address1`, `address2`, `town`, `region`, `postcode`,
 * `country`)
 *
 * @returns {string[]}
 *
 * @example
 * console.log(
 *   address({address1: 'Address Line 1', address2: '', town: 'Town', country: {name: 'UK'}}
 * );
 * // Output ['Address Line 1', 'Town', 'UK']
 *
 */
export function address(entity, fields = ['address1', 'address2', 'town', 'region', 'postcode', 'country']) {
  return fields
    .map(a => {
      if (a === 'country') {
        if (entity.country && entity.country.name) {
          return entity.country.name;
        }
        return null;
      } else if (a === 'address1' && entity.address_number) {
        return `${entity.address_number} ${entity.address1}`;
      } else {
        return entity[a];
      }
    })
    .filter(a => !!a);
}

/**
 * Format a date into a string with the format DD/MM/YYYY
 *
 * @function date
 *
 * @param {any} date A native JavaScript Date or Moment
 *
 * @returns {string}
 *
 * @example
 * console.log(
 *   date(moment().set({year: 2021, month: 6, date: 6}));
 * );
 * // Output '06/06/2021'
 *
 */
export function date(date) {
  return moment(date).format('DD/MM/YYYY');
}

/**
 * Format a date and time into a string with the format DD/MM/YYYY HH:mm
 *
 * @function dateTime
 *
 * @param {any} date A native JavaScript Date or Moment
 *
 * @returns {string}
 *
 * @example
 * console.log(
 *   date(moment().set({year: 2021, month: 6, date: 6, hour: 12, minute: 30}));
 * );
 * // Output '06/06/2021 12:30'
 *
 */
export function dateTime(date) {
  return moment(date).format('DD/MM/YYYY HH:mm');
}

/**
 * Format a time into a string with the format HH:mm
 *
 * @function time
 *
 * @param {any} date A native JavaScript Date or Moment
 *
 * @returns {string}
 *
 * @example
 * console.log(
 *   date(moment().set({hour: 12, minute: 30}));
 * );
 * // Output '12:30'
 *
 */
export function time(date) {
  return moment(date).format('HH:mm');
}

/**
 * Format a date into a string with the format dddd Do MMMM YYYY
 *
 * @function fullDate
 *
 * @param {any} date A native JavaScript Date or Moment
 *
 * @returns {string}
 *
 * @example
 * console.log(
 *   fullDate(moment().set({year: 2021, month: 6, date: 6}));
 * );
 * // Output 'Sunday 6th June 2021'
 *
 */
export function fullDate(date) {
  return moment(date).format('dddd Do MMMM YYYY');
}

/**
 * Format a date and time into a string with the format dddd Do MMMM YYYY HH:mm
 *
 * @function fullDate
 *
 * @param {any} date A native JavaScript Date or Moment
 *
 * @returns {string}
 *
 * @example
 * console.log(
 *   fullDateTime(moment().set({year: 2021, month: 6, date: 6, hour: 10, minute: 30}));
 * );
 * // Output 'Sunday 6th June 2021 10:30'
 *
 */
export function fullDateTime(date) {
  return moment(date).format('dddd Do MMMM YYYY HH:mm');
}

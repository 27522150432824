import AutorenewIcon from '@mui/icons-material/Autorenew';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  '@keyframes spin': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'}
  },
  spinning: {
    animationName: '$spin',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
}));

/**
 * A spinning arrow to indicate something is loading.
 *
 * @module Spinner
 *
 * @param {string} className Additional classes to style the spinner
 *
 * @example
 * <Spinner className={classes.spinner}/>
 *
 */
const Spinner = ({className}) => {
  const classes = useStyles();
  return <AutorenewIcon className={`${className} ${classes.spinning}`}/>;
};

Spinner.propTypes = {
  className: PropTypes.string
};

export default Spinner

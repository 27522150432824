import {Button, Tooltip as MatTooltip, Typography} from '@mui/material';
import {makeStyles, withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: `0 ${theme.spacing()} ${theme.spacing(2)} ${theme.spacing()}`,
    textTransform: 'none',
  }
}));

const Tooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    border: `3px solid ${theme.palette.grey['100']}`,
    borderRadius: '3px',
    color: theme.palette.text.primary,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
}))(MatTooltip);

/**
 * A button which displays a tooltip with title and description on hover
 *
 * @module TooltipButton
 *
 * @param {string} title The title to display in the tooltip
 * @param {string} label The label displayed on the button (will use title if undefined)
 * @param {string} description The description text displayed in the tooltip
 * @param {function} onClick The function to execute when the button is clicked
 *
 * @example
 * <TooltipButton
 *   title="Button A"
 *   description="A test button"
 *   label="buttonA"
 *   onClick={() => console.log('Clicked!')}
 * />
 *
 */
const TooltipButton = ({title, label, description, onClick}) => {
  const classes = useStyles();

  const handleClick = useCallback((e) => {
    e.preventDefault();
    if (onClick) {
      onClick({title, label, description});
    }
  }, [title, label, description, onClick]);

  return (
    <Tooltip title={
      <>
        <Typography color="inherit">{title}</Typography>
        {description}
      </>
    }>
      <Button
        className={classes.button}
        variant="contained"
        size="small"
        onMouseDown={handleClick}>
        {label ?? title}
      </Button>
    </Tooltip>
  )
}

TooltipButton.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func
};

export default TooltipButton;

import AnswerForm from '../modules/admin/forms/AnswerForm';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormDialog} from '@management-ui/core';
import {usePermissions} from '../hooks';


const AnswerModal = ({open, onClose, audit, section, question, onSaved}) => {
  const {userCan} = usePermissions();

  const [mode, setMode] = useState('standard');

  useEffect(() => {
    setMode(question?.is_required_file ? 'requiredFile' : 'standard');
  }, [question]);

  const toggleMode = useCallback(() => {
    setMode(mode === 'requiredFile' ? 'standard' : 'requiredFile')
  }, [mode]);

  const buttons = useMemo(() => {
    if (question.is_required_file && userCan(['moderate_audits'], true, false)) {
      return [
        {label: mode === 'requiredFile' ? 'Edit Rating' : 'Hide Rating', onClick: toggleMode}
      ];
    }
    return [];
  }, [question, userCan, mode, toggleMode]);

  return (
    <FormDialog
      title="Provide Assessment"
      open={open}
      maxWidth="lg"
      onClose={onClose}
      closeOnEscape={false}
      buttons={{save: 'Save', cancel: null}}
      additionalButtons={buttons}
      headerCloseTitle="Cancel"
      headerCloseMessage="Are you sure you want to cancel editing this assessment?"
      render={(props) => (
        <AnswerForm
          {...props}
          mode={mode}
          audit={audit}
          section={section}
          question={question}
          onSaved={onSaved}
        />
      )}
    />
  )
}

export default AnswerModal;
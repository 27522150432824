const isFunction = (functionToCheck) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const slugify = (text) => {
  return text.toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^a-z0-9-]*/g, '');
};

export {isFunction, reorder, slugify};


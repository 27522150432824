import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const TrustForm = forwardRef(({trust, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={trust?.arlo ? {...trust, arlo_id: trust.arlo.id} : trust}
      type="trust"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, name, arlo_id}) => services.trust.saveTrust({id, name, arlo_id})}
      onSaved={onSaved}
    />
  );
});

export default TrustForm;

import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({note = {}, prefix = ''}) => {
  return (
    <Panes
      entity={note}
      prefix={prefix}
      panes={[
        {
          title: '',
          fields: [
            <TextField
              name="comment"
              prefix={prefix}
              label="Comment"
              rules={{required: 'Please enter a comment for this note'}}
              fieldProps={{multiline: true, rows: 6}}
            />,
          ]
        }
      ]}
    />
  );
};

export default Fields;

import {API} from './API';
import {ServiceProvider} from './ServiceProvider';

export class AuthService {
  async login(email, password) {
    return (await API.getConnection())
      .post('auth/login', {email, password})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async me() {
    const key = 'currentUser';
    const {general: {online}} = API.store.getState();
    if (!online) {
      return new Promise((resolve, reject) => {
        const cached = ServiceProvider.getServices().cache.read(key);
        if (cached) {
          resolve(cached);
        } else {
          reject();
        }
      });
    }
    return (await API.getConnection())
      .get('auth/me')
      .then(response => {
        const user = response.data.data;
        ServiceProvider.getServices().cache.write(key, user);
        return user;
      })
      .catch(error => API.handleError(error));
  }

  async logout() {
    return (await API.getConnection())
      .post('auth/logout')
      .then(() => {
      })
      .catch(error => API.handleError(error));
  }

  async forgotPassword(email, resetUrl) {
    return (await API.getConnection())
      .post('auth/forgot-password', {email, reset_url: resetUrl})
      .then(() => {
      })
      .catch(error => API.handleError(error));
  }

  async resetPassword(email, password, token) {
    return (await API.getConnection())
      .post('auth/reset-password', {email, password, token})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }
}

import {Panes, prefixWithSeparator, RelationAutocomplete} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useContext} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';

const LinkFields = ({question = {}, prefix = '', searchFilters = {}, multiplePanes = true, children}) => {
  const services = useContext(ServiceContext);
  const {/** @var {function(string): string} */getValues} = useFormContext();

  return (
    <Panes
      entity={question}
      prefix={prefix}
      panes={[
        {
          title: 'Link an Existing Question',
          fields: [
            <Box paddingBottom={16}>
              <RelationAutocomplete
                valueName="question_id"
                titleName="question_body"
                prefix={prefix}
                label="Question"
                rules={{
                  validate: () => {
                    const fieldPrefix = prefixWithSeparator(prefix);
                    if (!getValues(`${fieldPrefix}question_id`)) {
                      return 'Please select an existing question';
                    }
                    return true;
                  }
                }}
                loadOptions={query => (
                  services.question.getQuestions({filter: {search: query, ...searchFilters}}, 1, 50)
                    .then(response => response.map(question => ({title: question.body, value: question.id})))
                    .catch(() => [])
                )}
              />
            </Box>,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Link an Existing Question'}
    >{children}</Panes>
  );
};

export default LinkFields;

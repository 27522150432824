import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import routes from '../../../../routes';
import Navigation from '../AuditType/Navigation';
import SectionQuestions from './SectionQuestions';

const SectionDetail = ({audit, section, onSaved, onAuditUpdated}) => {
  const history = useHistory();

  const handleSelect = useCallback((selected) => history.push(selected ? reverse(routes.audits.section, {
      id: audit.id,
      tab: 'ratings',
      sectionID: selected.id
    }) : reverse(routes.audits.index, {id: audit.id})
  ), [history, audit]);

  return audit ? (
    <Navigation
      items={audit.sections.map(s => ({...s, menuTitle: `${s.index}. ${s.title}`}))}
      current={section.id}
      onSelect={handleSelect}
    >
      <Box marginTop={2}>
        <SectionQuestions
          audit={audit}
          section={section}
          onSaved={onSaved}
          onUpdated={onAuditUpdated}
          onNavigate={handleSelect}/>
      </Box>
    </Navigation>
  ) : null;
};

export default SectionDetail;

import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({type = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={type}
      prefix={prefix}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title for this type'}}
            />,
          ]
        }, {
          title: 'Content',
          fields: [
            <TextField
              name="introduction"
              prefix={prefix}
              label="Introduction"
              fieldProps={{multiline: true, rows: 20}}
            />,
          ]
        },
      ]}
      title={multiplePanes ? null : 'Category Details'}
    >{children}</Panes>
  );
};

export default Fields;

import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)} 0 0`,
  },

  tabs: {
    alignItems: 'center',
    display: 'flex',
    listStyle: 'none',
    margin: `${theme.spacing(2)} 0`,
    padding: 0,
    maxWidth: 400,
    width: '100%',

    '& li': {
      display: 'flex',
      flex: 1,

      '& > a': {
        border: `2px solid ${theme.palette.primary.main}`,
        borderLeftWidth: 1,
        borderRightWidth: 1
      },

      '&:first-of-type > a': {
        borderBottomLeftRadius: theme.spacing(2),
        borderLeftWidth: 2,
        borderTopLeftRadius: theme.spacing(2),
      },

      '&:last-of-type > a': {
        borderBottomRightRadius: theme.spacing(2),
        borderRightWidth: 2,
        borderTopRightRadius: theme.spacing(2),
      },
    },

    '& a': {
      backgroundColor: theme.palette.common.white,
      flex: 1,
      padding: theme.spacing(1),
      textAlign: 'center',
      textDecoration: 'none',

      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        fontWeight: 600,
      },

      '&.active': {
        backgroundColor: theme.palette.secondary.main,

        '& .MuiTypography-root': {
          color: theme.palette.primary.contrastText,
        },
      }
    }
  }
}));

const Switcher = ({tabs}) => {
  const location = useLocation();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ul className={classes.tabs}>
        {tabs.map(({title, path}, index) => (
          <li key={index}>
            <Link
              className={location.pathname === path ? 'active' : ''}
              to={path}
            >
              <Typography>{title}</Typography>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Switcher;

import {API} from './API';

export class AttributeService {

  constructor(prefix) {
    this.prefix = prefix;
  }

  async getAttributes() {
    return (await API.getConnection())
      .get(this.prefix)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveAttribute(data) {
    let method = 'post';
    let url = this.prefix;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteAttribute(attribute) {
    return (await API.getConnection()).delete(`${this.prefix}/${attribute.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async reorderAttributes(attributes) {
    return (await API.getConnection())
      .put(this.prefix, {items: attributes})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}

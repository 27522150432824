import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import SettingsFields from './SettingsFields';

const getComponent = (type) => {
  switch (type) {
    case 'settings':
      return SettingsFields;
    default:
      return null;
  }
}

const SettingsForm = forwardRef(({type = 'settings', settings, onSaved, onError}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const handleSave = useCallback((update) => {
    const Fields = getComponent(type);
    return services.settings.saveSettings(Fields.getValues(update)).catch(error => {
      onError(error);
      return Promise.reject(error);
    })
  }, [services, type, onError]);

  return (
    <BaseForm
      ref={formRef}
      entity={settings}
      type="settings"
      fieldsComponent={(props) => {
        const Fields = getComponent(type);
        return <Fields {...props} />;
      }}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default SettingsForm;

import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const NoteForm = forwardRef(({audit, note, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={note}
      type="note"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({comment}) => services.audit.saveNote(audit, comment)}
      onSaved={onSaved}
    />
  );
});

export default NoteForm;

import {ConfirmationDialog, DataTable} from '@management-ui/core';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import TrustForm from '../../forms/TrustForm';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const [selected, setSelected] = useState(null);
  const [showRestore, setShowRestore] = useState(false);

  const handleSelect = useCallback((trust) => {
    if (!archive) {
      history.push(reverse(routes.trusts.detail, {id: trust.id}));
    } else {
      setSelected(trust);
      setShowRestore(true);
    }
  }, [history, archive]);

  const handleRestore = useCallback((confirmed) => {
    setShowRestore(false);
    if (confirmed) {
      services.trust.restoreTrust(selected).then(() => {
        if (tableRef.current) {
          tableRef.current.refresh();
        }
      }).catch(() => null);
    }
  }, [services, selected])

  return (
    <>
      <DataTable
        ref={tableRef}
        title="Trusts"
        columns={useMemo(() => [
          {title: 'Name', field: 'name'}
        ], [])}
        loadData={useCallback(query => new Promise((resolve, reject) => {
          services.trust.getTableTrusts(query, archive ? {archive: 'only'} : {})
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(() => reject());
        }), [services, archive])}
        newForm={{
          title: 'Link New Trust',
          render: (props) => <TrustForm trust={{}} {...props}/>,
          onSaved: handleSelect
        }}
        onRowClick={handleSelect}
      />
      <ConfirmationDialog
        title="Restore Trust"
        message="Are you sure you want to restore this trust?"
        open={showRestore}
        onClose={handleRestore}
      />
    </>
  );
};

export default Table;

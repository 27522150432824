import {Panes, TextField} from '@management-ui/core';
import React from 'react';
import ArloOrganisationLookup from '../ArloOrganisationLookup';

const Fields = ({trust = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={trust}
      prefix={prefix}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <ArloOrganisationLookup
              nameField="name"
              idField="arlo_id"
              prefix={prefix}
            />,
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name for this trust'}}
            />,
            <TextField
              name="arlo_id"
              prefix={prefix}
              label="Arlo ID"
              rules={{required: 'Please enter an Arlo ID for this trust'}}
            />,
          ]
        }, {
          title: '',
          fields: []
        },
      ]}
      title={multiplePanes ? null : 'Trust Details'}
    >{children}</Panes>
  );
};

export default Fields;

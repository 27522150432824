import {Box, FormControl, FormHelperText, Grid, Slider, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import moment from '../moment';
import {prefixWithSeparator} from './utilities';

const useStyles = makeStyles(() => ({
  labels: {
    display: 'flex',
    justifyContent: 'space-between',

    '& p': {
      fontWeight: 700,
      margin: 0,
      padding: 0,
    }
  }
}));

/**
 * A using a Material UI slider to select a time range for use within the `BaseForm` component.
 *
 * @module TimeRangeSelector
 *
 * @param {string} fromName The name of the from field
 * @param {string} toName The name of the to field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {number} min The minimum value selectable
 * @param {number} max The maximum value selectable
 * @param {number} step The size of the gap between each step
 * @param {object} fieldProps Any additional props for the [Slider](https://material-ui.com/components/slider/)
 *
 * @example
 * <TimeRangeSelector
 *   fromName="from"
 *   toName="to"
 *   prefix={prefix}
 *   label="Time Range"
 *   rules={{required: 'Please enter a time range'}}
 *   min={6}
 *   max={20}
 *   step={1}
 * />
 *
 */
const TimeRangeSelector = (
  {
    fromName,
    toName,
    prefix = '',
    label,
    id = null,
    rules = {},
    min = 6,
    max = 20,
    step = 0.5,
    fieldProps
  }
) => {
  const classes = useStyles();
  const {control, formState: {errors}, watch, setValue} = useFormContext();

  const formTime = hours => {
    return moment()
      .set({hour: 0, minute: 0, second: 0, millisecond: 0})
      .add(hours, 'hours');
  };

  const timeToHours = time => {
    return time.hour() + (time.minute() / 60)
  };

  const prefixedFromName = `${prefixWithSeparator(prefix)}${fromName}`;
  const prefixedToName = `${prefixWithSeparator(prefix)}${toName}`;
  // noinspection JSCheckFunctionSignatures
  const fromValue = watch(prefixedFromName, formTime(min).toISOString());
  // noinspection JSCheckFunctionSignatures
  const toValue = watch(prefixedToName, formTime(min + step).toISOString());
  const fromValueRef = useRef(formTime(min).toISOString());
  const toValueRef = useRef(formTime(min + step).toISOString());

  const [range, setRange] = useState([min, min + step]);

  useEffect(() => {
    if (fromValue != null && fromValue !== fromValueRef.current) {
      fromValueRef.current = fromValue;
      const time = moment(fromValue);
      setRange([timeToHours(time), timeToHours(moment(toValueRef.current))]);
    }
  }, [fromValue]);

  useEffect(() => {
    if (toValue != null && toValue !== toValueRef.current) {
      toValueRef.current = toValue;
      const time = moment(toValue);
      setRange([timeToHours(moment(fromValueRef.current)), timeToHours(time)]);
    }
  }, [toValue]);

  const handleRange = useCallback((selected) => {
    setRange(selected);
    setValue(prefixedFromName, formTime(selected[0]).toISOString());
    setValue(prefixedToName, formTime(selected[1]).toISOString());
  }, [setValue, prefixedFromName, prefixedToName]);

  return (
    <FormControl
      variant="outlined"
      required={rules && !!rules.required}
      fullWidth>
      <Box marginX={2} marginTop={2}><Typography variant="body2">{label}</Typography></Box>
      <Controller
        name={prefixedFromName}
        control={control}
        rules={rules}
        render={() => null}
      />
      <Controller
        name={prefixedToName}
        control={control}
        rules={rules}
        render={() => (
          <>
            <Box padding={2}>
              <Grid container={true} direction="column">
                <Slider
                  {...fieldProps}
                  id={id ?? prefixedFromName}
                  marks
                  min={min}
                  max={max}
                  step={step}
                  value={range}
                  onChange={(_e, values) => handleRange(values)}
                />
              </Grid>
            </Box>
            <div className={classes.labels}>
              <p>{formTime(range[0]).format('k:mm')}</p>
              <p>{formTime(range[1]).format('k:mm')}</p>
            </div>
          </>
        )}
      />
      {!!errors[fromName] ? <FormHelperText>{errors[fromName].message}</FormHelperText> : null}
    </FormControl>
  );
};

TimeRangeSelector.propTypes = {
  fromName: PropTypes.string,
  toName: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  fieldProps: PropTypes.object
};

export default TimeRangeSelector;

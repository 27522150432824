import {SquareButton} from '@management-ui/core';
import {CloudUpload, Delete, Edit} from '@mui/icons-material';
import {Link, styled} from '@mui/material';
import React from 'react';
import Text from '../../../../components/Text';
import {FULLY_MET, NOT_MET, PARTLY_MET} from '../../../../constants';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const Row = styled('div')(({theme}) => ({
  borderTop: `1px solid ${theme.palette.grey['300']}`,
  display: 'flex',

  '&:first-of-type': {
    borderTopWidth: 0,
  },

  '& > div': {
    '&:first-of-type': {
      borderLeftWidth: 0,
    },
  },

  '&.headers > div': {
    fontWeight: 500,
  }
}));

const Cell = styled('div')(({theme}) => ({
  borderLeft: `1px solid ${theme.palette.grey['300']}`,
  flex: 2,
  padding: theme.spacing(1),

  '&.small': {
    flex: 1,
  },

  '&.action': {
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 40px',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
    width: 40,
  },

  '&.rating0': {
    backgroundColor: theme.palette.grey['100'],
  },

  '&.rating1': {
    backgroundColor: NOT_MET,
    color: theme.palette.common.white,
  },

  '&.rating2': {
    backgroundColor: PARTLY_MET,
    color: theme.palette.common.white,
  },

  '&.rating3': {
    backgroundColor: FULLY_MET,
    color: theme.palette.common.white,
  },

  '&.actions': {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',

    '& > h4': {
      margin: 0,
      padding: 0,
    },

    '& > p': {
      margin: `${theme.spacing(0.5)} 0 ${theme.spacing(1)}`,
      padding: 0,

      '&:last-of-type': {
        marginBottom: 0,
      }
    }
  },
}));

const Files = styled('ul')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  width: '100%',

  '& li': {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: `${theme.spacing(0.25)} 0`,

    '& span': {
      flex: 1,

      '&:hover': {
        textDecoration: 'underline',
      }
    },
  }
}));


const Questions = (
  {
    questions,
    onEdit = null,
    onDelete = null,
    onDeleteFile = null,
    onDownload = null,
    onUpload = null
  }
) => (
  <Container>
    <Row className="headers">
      {onEdit ? <Cell className="action"/> : null}
      <Cell/>
      <Cell className="small">Rating</Cell>
      <Cell>Evidence</Cell>
      <Cell>Actions</Cell>
      <Cell>Files</Cell>
      {onUpload ? <Cell className="action"/> : null}
    </Row>
    {questions.map((question, index) => {
      const ref = `${question.sectionIndex}.${question.index}`;
      let rating = '-';
      switch (`${question.rating}`) {
        case '1':
          rating = 'Not Met';
          break;
        case '2':
          rating = 'Partly Met';
          break;
        case '3':
          rating = 'Fully Met';
          break;
        default:
          break;
      }
      return (
        <Row key={index}>
          {onEdit ? (
            <Cell className="action">
              <SquareButton
                icon={<Edit/>}
                small={true}
                tooltip={`Assess ${ref}`}
                onClick={() => onEdit(question)}
              />
              {onDelete ? (
                <SquareButton
                  icon={<Delete/>}
                  small={true}
                  tooltip={`Delete ${ref}`}
                  onClick={() => onDelete(question)}
                />
              ) : null}
            </Cell>
          ) : null}
          <Cell>
            <strong>{ref}</strong>
            <br/><Text text={question.body}/>
            {question.link ? (<><br/><Link href={question.link} target="_blank">Find out more</Link></>) : null}
          </Cell>
          <Cell className={`small rating${question.rating ?? 0}`}><strong>{rating}</strong></Cell>
          <Cell>{question.evidence ? <Text text={question.evidence}/> : '-'}</Cell>
          <Cell className="actions">
            <h4>Lead Person</h4>
            <p>{question.lead ?? '-'}</p>
            <h4>Action</h4>
            <p>{question.action ? <Text text={question.action}/> : '-'}</p>
            <h4>Impact</h4>
            <p>{question.impact ?? '-'}</p>
            <h4>Timescale</h4>
            <p>{question.timescale ?? '-'}</p>
            <h4>Progress</h4>
            <p>{question.progress ? <Text text={question.progress}/> : '-'}</p>
          </Cell>
          <Cell>
            <Files>
              {question.files.map((file, fileIndex) => (
                <li key={`${index}-${fileIndex}`}>
                  <span onClick={() => onDownload ? onDownload(file) : null}>{file.name}</span>
                  {onDeleteFile ? (
                    <Delete fontSize="x-small" onClick={() => onDeleteFile(file)}/>
                  ) : null}
                </li>
              ))}
            </Files>
          </Cell>
          {onUpload ? (
            <Cell className="action">
              <SquareButton
                icon={<CloudUpload/>}
                small={true}
                tooltip={`Upload File for ${ref}`}
                onClick={() => onUpload(question)}
              />
            </Cell>
          ) : null}
        </Row>
      )
    })}
  </Container>
);

export default Questions;

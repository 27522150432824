import {Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  notice: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '1.2em',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
  }
}));

/**
 * A small component to display an warning or notice.
 *
 * @module Notice
 *
 * @param {boolean} display Whether the notice should be visible.
 * @param {string} message The message to display on the notice.
 *
 * @example
 * <Notice display={true} message="This notice is visible!"/>
 *
 */
const Notice = ({display, message}) => {
  const classes = useStyles();
  return display ? (
    <Paper className={classes.notice}>{message}</Paper>
  ) : null;
};

Notice.propTypes = {
  display: PropTypes.bool,
  message: PropTypes.string
};

export default Notice;

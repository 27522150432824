import * as qs from 'qs';
import {API} from '../API';

export class TypeService {
  async getTableTypes(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getTypes(query, page, length);
  }

  async getTypes(query, page, length) {
    return (await API.getConnection())
      .get('audit-types', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getType(id, archive = false) {
    return (await API.getConnection())
      .get(`audit-types/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveType(data) {
    let method = 'post';
    let url = 'audit-types';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async copyType(type) {
    return (await API.getConnection())
      .post(`audit-types/${type.id}/copy`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async updateAudits(type) {
    return (await API.getConnection())
      .post(`audit-types/${type.id}/audits`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteType(type, questions = false) {
    return (await API.getConnection())
      .delete(`audit-types/${type.id}${questions ? '?questions=1' : ''}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async restoreType(type) {
    return (await API.getConnection())
      .put(`audit-types/${type.id}/restore`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}

import * as qs from 'qs';
import {API} from './API';

export class ArloService {
  async searchOrganisations(query) {
    return (await API.getConnection())
      .get('arlo/organisations', {
        params: {query},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data['organisations'])
      .catch(error => API.handleError(error));
  }
}

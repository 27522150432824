import {FormControlLabel, Switch} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useFormStyles} from './styles';
import {prefixWithSeparator} from './utilities';

/**
 * A checkbox field for use within the `BaseForm` component.
 *
 * @module Checkbox
 *
 * @param {string} name The name for field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {object} fieldProps Any additional props for the Material UI [TextField](https://material-ui.com/api/text-field/)
 *
 * @example
 * <Checkbox
 *   name="is_active"
 *   prefix={prefix}
 *   label="Is Active?"
 *   id="isActive"
 *   rules={{required: 'Please confirm they are active'}}
 * />
 *
 */
const Checkbox = (
  {
    name,
    prefix = '',
    label,
    id = null,
    rules = {},
    fieldProps
  }
) => {
  const classes = useFormStyles();
  const {control, formState: {errors}} = useFormContext();
  const prefixedName = `${prefixWithSeparator(prefix)}${name}`
  return (
    <Controller
      name={prefixedName}
      control={control}
      rules={rules}
      render={({field}) => (
        <FormControlLabel
          className={classes.checkbox}
          checked={!!field.value}
          error={!!errors[prefixedName] ? errors[prefixedName].message : ''}
          required={rules && !!rules.required}
          control={
            <Switch
              {...fieldProps}
              {...field}
              id={id ?? prefixedName}
              color="primary"
            />
          }
          label={label}
        />
      )}/>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object
};

export default Checkbox;

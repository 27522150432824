import * as qs from 'qs';
import {API} from './API';

export class QuestionService {

  async getTableQuestions(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getQuestions(query, page, length);
  }

  async getQuestions(query = {}, page = undefined, length = undefined) {
    return (await API.getConnection())
      .get('questions', {
        params: {...query, ...(page ? {page} : {}), ...(length ? {length} : {})},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => length ? response.data : response.data.data)
      .catch(API.handleError);
  }

  async saveQuestion(data) {
    let method = 'post';
    let url = 'questions';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteQuestion(question) {
    return (await API.getConnection())
      .delete(`questions/${question.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async restoreQuestion(question) {
    return (await API.getConnection())
      .put(`questions/${question.id}/restore`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async reorderQuestions(questions) {
    return (await API.getConnection())
      .put('questions', {questions})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}

import {ConfirmationDialog, FormDialog, ReorderingPanel} from '@management-ui/core';
import {Visibility} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import AuditTypeSectionForm from '../../forms/AuditTypeSectionForm';

const Sections = ({type, onUpdated}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState({});

  const formatSections = useCallback((list) => (
    list.map((section) => ({
      entity: section,
      attributes: [`${section.index}. ${section.title}`, `${section.questions.length} question(s)`]
    }))
  ), []);

  useEffect(() => {
    if (type) {
      setSections(formatSections(type.sections));
    }
  }, [type, formatSections]);

  const goToDetail = useCallback((section) => {
    history.push(reverse(routes.auditTypes.section, {id: type.id, sectionID: section.id}));
  }, [history, type]);

  const handleSaved = useCallback((updated) => {
    setShowEdit(false);
    onUpdated(updated);
  }, [onUpdated]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.auditType.section.deleteSection(type, selected).then(updated => {
        setLoading(false);
        onUpdated(updated);
      }).catch(() => setLoading(false));
    }
  }, [services, type, selected, onUpdated]);

  const handleReorder = useCallback((reordered) => {
    setSections(formatSections(reordered));
    return services.auditType.section.reorderSections(type, reordered.map((section, index) => ({
      id: section.id,
      index: index + 1
    })));
  }, [services, type, formatSections]);

  return (
    <>
      <ReorderingPanel
        title="Sections"
        items={sections}
        loading={loading}
        onLoading={setLoading}
        controls={[
          {
            title: 'Manage Section',
            icon: <Visibility/>,
            onClick: (section) => goToDetail(section)
          },
          {
            title: 'Delete Section',
            icon: <DeleteIcon/>,
            onClick: (section) => setSelected(section) || setShowDelete(true)
          }
        ]}
        onNew={() => setShowEdit(true)}
        onReorder={handleReorder}
        onReordered={onUpdated}
      />
      <FormDialog
        title="Add New Section"
        open={showEdit}
        maxWidth="md"
        onClose={() => setShowEdit(false)}
        render={useMemo(() => (props) => (
          <AuditTypeSectionForm {...props} type={type} section={{}} onSaved={handleSaved}/>
        ), [type, handleSaved])}
      />
      <ConfirmationDialog
        title="Delete Section"
        message="Are you sure you want to delete this section?"
        open={showDelete}
        onClose={handleDelete}
      />
    </>
  );
}

export default Sections;

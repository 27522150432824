import {ConfirmationDialog, date, dateTime, DetailColumns, DetailPane, FormDialog, name} from '@management-ui/core';
import {
  Archive,
  ArrowBack,
  Assessment,
  Check,
  CheckCircle,
  CopyAll,
  Edit,
  PlaylistAddCheck,
  SwapVert
} from '@mui/icons-material';
import {Link} from '@mui/material';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import AuditStatus from '../../../../components/AuditStatus';
import {ServiceContext} from '../../../../components/Services';
import {usePermissions} from '../../../../hooks';
import routes from '../../../../routes';
import AuditForm from '../../forms/AuditForm';
import CopyAuditForm from '../../forms/CopyAuditForm';
import DateForm from '../../forms/DateForm';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
}));

const AuditDetail = ({audit, onLoading, openDialogs, toggleDialog, onAuditUpdated}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const history = useHistory();
  const {userCan} = usePermissions();
  const [canEdit, setCanEdit] = useState(false);
  const [canComplete, setCanComplete] = useState(false);
  const [canIncomplete, setCanIncomplete] = useState(false);
  const [canApprove, setCanApprove] = useState(false);
  const [canReindex, setCanReindex] = useState(false);
  const {online} = useSelector(state => state['general']);

  useEffect(() => {
    setCanEdit(userCan(['moderate_audits'], true, false));
    const allRated = audit.sections.filter(s => s.questions.filter(q => q.rating < 1).length > 0).length < 1;
    switch (audit.status) {
      case 'Assigned':
      case 'Requires Amends':
        setCanComplete(allRated && userCan(['manage_audits'], true, false));
        setCanIncomplete(false);
        break;
      case 'Complete':
        setCanComplete(false);
        setCanIncomplete(userCan(['moderate_audits'], true, false));
        break;
      case 'Approved':
        setCanComplete(false);
        setCanIncomplete(false);
        break;
      default:
        break;
    }
    setCanApprove(audit.status !== 'Approved' && allRated && userCan(['moderate_audits'], true, false));
    setCanReindex(userCan(['moderate_audits'], true, false));
  }, [audit, userCan]);

  const handleUpdateAuditReviewDate = useCallback(async ({date}) => {
    onLoading(true);
    return services.audit.saveAudit({
      id: audit.id,
      reviewed: date ? moment(date).format('YYYY-MM-DD') : null
    }).then((updated) => {
      onLoading(false);
      onAuditUpdated(updated);
      toggleDialog('editAuditReviewDate', false);
    }).catch(() => onLoading(false));
  }, [services, audit, onLoading, toggleDialog, onAuditUpdated]);

  const handleStatus = useCallback((status, confirmed) => {
    toggleDialog(`${status}Audit`, false);
    if (confirmed) {
      onLoading(true);
      services.audit[`${status}Audit`](audit).then((updated) => {
        onLoading(false);
        onAuditUpdated(updated);
      }).catch(() => onLoading(false));
    }
  }, [services, audit, onLoading, toggleDialog, onAuditUpdated]);

  const handleReport = useCallback(() => {
    onLoading(true);
    services.audit.downloadReport(audit)
      .then(() => onLoading(false))
      .catch(() => onLoading(false));
  }, [services, audit, onLoading]);

  const handleCriteria = useCallback(() => {
    onLoading(true);
    services.audit.downloadCriteria(audit)
      .then(() => onLoading(false))
      .catch(() => onLoading(false));
  }, [services, audit, onLoading]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('deleteAudit', false);
    if (confirmed) {
      onLoading(true);
      services.audit.deleteAudit(audit).then(() => {
        onLoading(false);
        history.push(routes.audits.index);
      }).catch(() => onLoading(false));
    }
  }, [services, audit, onLoading, toggleDialog, history]);

  return (
    <>
      <DetailColumns columns={[

        <DetailPane
          title="Audit Details"
          actions={[
            ...(online && canEdit ? [
              {title: 'Edit Audit', icon: <Edit/>, onClick: () => toggleDialog('editAudit', true)},
              {title: 'Copy from Another Audit', icon: <CopyAll/>, onClick: () => toggleDialog('copyAudit', true)},
            ] : []),
            ...(online && canComplete ? [
              {title: 'Mark Audit as Complete', icon: <Check/>, onClick: () => toggleDialog('completeAudit', true)},
            ] : []),
            ...(online && canIncomplete ? [
              {
                title: 'Send Audit back for Amends',
                icon: <ArrowBack/>,
                onClick: () => toggleDialog('incompleteAudit', true)
              },
            ] : []),
            ...(online && canApprove ? [
              {
                title: 'Mark Audit as Approved',
                icon: <CheckCircle/>,
                onClick: () => toggleDialog('approveAudit', true)
              },
            ] : []),
            ...(online && canReindex ? [
              {
                title: 'Re-index Audit',
                icon: <SwapVert/>,
                onClick: () => toggleDialog('reindexAudit', true)
              },
            ] : []),
            ...(online ? [{title: 'Download Report', icon: <Assessment/>, onClick: handleReport}] : []),
            ...(online ? [{title: 'Download Criteria', icon: <PlaylistAddCheck/>, onClick: handleCriteria}] : []),
            ...(online ? [{
              title: 'Archive Audit',
              icon: <Archive/>,
              onClick: () => toggleDialog('deleteAudit', true)
            }] : [])
          ]}
          details={[
            {title: 'Category', value: audit.title},
            {
              title: 'School',
              value: audit.school.name,
              route: audit.school.trust ?
                reverse(routes.trusts.school, {trustID: audit.school.trust.id, id: audit.school.id})
                :
                reverse(routes.schools.detail, {id: audit.school.id})
            },
            {title: 'Auditor', value: name(audit.user), route: reverse(routes.users.detail, {id: audit.user.id})},
            {title: 'Date', value: audit.date ? date(audit.date) : '-'},
            {
              title: 'Progress Review Date', value: (
                <>
                  <span>{audit.reviewed ? date(audit.reviewed) : '-'}</span>
                  {canEdit ? (
                    <>
                      <br/>
                      <Link
                        className={classes.link}
                        onClick={() => toggleDialog('editAuditReviewDate', true)}
                      >Update</Link>
                    </>
                  ) : null}
                </>
              )
            }
          ]}
          dialogs={[
            (props) => (
              <FormDialog
                {...props}
                title="Edit Audit"
                open={openDialogs['editAudit']}
                maxWidth="md"
                onClose={() => toggleDialog('editAudit', false)}
                render={(props) => (
                  <AuditForm
                    {...props}
                    audit={audit}
                    onSaved={(updated) => onAuditUpdated(updated) || toggleDialog('editAudit', false)}
                  />
                )}
              />
            ),
            (props) => (
              <FormDialog
                {...props}
                title="Update Review Date"
                open={openDialogs['editAuditReviewDate']}
                maxWidth="sm"
                onClose={() => toggleDialog('editAuditReviewDate', false)}
                render={(props) => (
                  <DateForm
                    {...props}
                    entity={{date: audit.reviewed}}
                    onSave={handleUpdateAuditReviewDate}
                    sectionTitle="Progress Review Date"
                    fieldTitle="Select a Date"
                    onSaved={() => null}
                  />
                )}
              />
            ),
            (props) => (
              <FormDialog
                {...props}
                title="Copy Audit"
                open={openDialogs['copyAudit']}
                maxWidth="md"
                onClose={() => toggleDialog('copyAudit', false)}
                render={(props) => (
                  <CopyAuditForm
                    {...props}
                    audit={audit}
                    onSaved={(updated) => onAuditUpdated(updated) || toggleDialog('copyAudit', false)}
                  />
                )}
              />
            ),
            (props) => (
              <ConfirmationDialog
                {...props}
                title="Complete Audit"
                message="Are you sure you want to mark this audit as complete? You won't be able to edit it any further"
                open={openDialogs['completeAudit']}
                onClose={(confirmed) => handleStatus('complete', confirmed)}
              />
            ),
            (props) => (
              <ConfirmationDialog
                {...props}
                title="Amend Audit"
                message="Are you sure you want to mark this audit as requiring amends?"
                open={openDialogs['incompleteAudit']}
                onClose={(confirmed) => handleStatus('incomplete', confirmed)}
              />
            ),
            (props) => (
              <ConfirmationDialog
                {...props}
                title="Approve Audit"
                message="Are you sure you want to mark this audit as approved?"
                open={openDialogs['approveAudit']}
                onClose={(confirmed) => handleStatus('approve', confirmed)}
              />
            ),
            (props) => (
              <ConfirmationDialog
                {...props}
                title="Re-index Audit"
                message="Are you sure you want to re-index this audit? Doing so will correct any numbering issues"
                open={openDialogs['reindexAudit']}
                onClose={(confirmed) => handleStatus('reindex', confirmed)}
              />
            ),
            (props) => (
              <ConfirmationDialog
                {...props}
                title="Archive Audit"
                message="Are you sure you want to archive this audit? This can be restored later if needed."
                open={openDialogs['deleteAudit']}
                onClose={handleDelete}
              />
            ),
          ]}
        />,

        <DetailPane
          title="User Details"
          actions={canEdit ? [
            {title: 'Edit User Details', icon: <Edit/>, onClick: () => toggleDialog('editUser', true)},
          ] : []}
          details={[
            {title: 'Person Completing the Audit', value: audit.name ?? '-'},
            {title: 'Job Title', value: audit.job_title ?? '-'},
            ...(audit.head_teacher ? [{title: 'Head Teacher', value: audit.head_teacher}] : []),
            ...(audit.chair_of_governors ? [{title: 'Chair of Governors', value: audit.chair_of_governors}] : []),
            ...(audit.safeguarding_governor ? [{
              title: 'Safeguarding Governor',
              value: audit.safeguarding_governor
            }
            ] : []),
            ...(audit.ceo ? [{title: 'Trust CEO', value: audit.ceo}] : []),
            ...(audit.chair_of_trustees ? [{title: 'Chair of Trustees', value: audit.chair_of_trustees}] : []),
            ...(audit.safeguarding_trustee ? [{
              title: 'Safeguarding Trustee',
              value: audit.safeguarding_trustee
            }] : []),
            {title: 'Date of Formal Sign-off', value: audit.signed_off ? date(audit.signed_off) : '-'},
            {title: 'Ofsted Rating', value: audit.school?.rating?.name ?? '-'}
          ]}
          dialogs={[
            (props) => (
              <FormDialog
                {...props}
                title="Edit User Details"
                open={openDialogs['editUser']}
                maxWidth="sm"
                onClose={() => toggleDialog('editUser', false)}
                closeOnEscape={false}
                buttons={{save: 'Save', cancel: null}}
                headerCloseTitle="Cancel"
                headerCloseMessage="Are you sure you want to cancel editing this audit?"
                render={(props) => (
                  <AuditForm
                    {...props}
                    audit={audit}
                    includeAssignment={false}
                    includeUser={true}
                    onSaved={(updated) => onAuditUpdated(updated) || toggleDialog('editUser', false)}
                  />
                )}
              />
            ),
          ]}
        />,

        <DetailPane
          title="Admin Details"
          details={[
            {title: 'Status', value: <AuditStatus status={audit.status}/>},
            {title: 'Created', value: dateTime(audit.created_at)},
            {title: 'Last Updated', value: dateTime(audit.updated_at)}
          ]}
        />,
      ]}/>
    </>
  );
};

export default AuditDetail;

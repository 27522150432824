import React, {createContext} from 'react';
import {ArloService} from '../services/ArloService';
import {AttributeService} from '../services/AttributeService';
import {AuditService} from '../services/AuditService';
import {QuestionService as AuditQuestionService} from '../services/AuditType/QuestionService';
import {SectionService} from '../services/AuditType/SectionService';
import {TypeService} from '../services/AuditType/TypeService';
import {AuthService} from '../services/AuthService';
import {CacheService} from '../services/CacheService';
import {QuestionService} from '../services/QuestionService';
import {SchoolService} from '../services/SchoolService';
import {ServiceProvider} from '../services/ServiceProvider';
import {SettingsService} from '../services/SettingsService';
import {TrustService} from '../services/TrustService';
import {UserService} from '../services/UserService';

export const ServiceContext = createContext({});

export default function Services({children}) {
  const services = {
    arlo: new ArloService(),
    attribute: {
      ofstedRating: new AttributeService('ofsted-ratings'),
      schoolType: new AttributeService('school-types'),
    },
    auditType: {
      type: new TypeService(),
      section: new SectionService(),
      question: new AuditQuestionService(),
    },
    audit: new AuditService(),
    auth: new AuthService(),
    cache: new CacheService(),
    question: new QuestionService(),
    school: new SchoolService(),
    settings: new SettingsService(),
    trust: new TrustService(),
    user: new UserService()
  };
  ServiceProvider.setServices(services);
  return (
    <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
  );
}

import {IconButton, Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => {
  return {
    button: {
      alignItems: 'center',
      background: 'none',
      border: 0,
      display: 'flex',
      height: 64,
      justifyContent: 'center',
      width: 64,
    },

    small: {
      height: 40,
      width: 40,
    },
  };
});

/**
 * A square icon button with tooltip for accessibility.
 *
 * @module SquareButton
 *
 * @param {string} tooltip The tooltip to show on hover
 * @param {node} icon The icon to display
 * @param {function} onClick The callback to execute when the button is clicked
 * @param {boolean} disabled A switch to determine whether the button is active
 * @param {string} additionalClasses Extra classes which are attached to the container
 * @param {boolean} small An option to set the button as small
 *
 * @example
 * <SquareButton
 *   tooltip="Edit"
 *   icon={<EditIcon/>>}
 *   onClick={() => console.log('Clicked')}
 *   disabled={false}
 *   additionalClasses={classes.button}
 *   small={false}
 * />
 *
 */
const SquareButton = (
  {
    tooltip,
    icon,
    onClick,
    disabled = false,
    additionalClasses = '',
    small = false,
  }
) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.button} ${
        small ? classes.small : ''
      } ${additionalClasses}`}>
      <Tooltip title={tooltip}>
        <span>
          <IconButton
            color="inherit"
            onClick={onClick}
            disabled={disabled}
            size={small ? 'small' : 'medium'}>
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}

SquareButton.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  additionalClasses: PropTypes.string,
  small: PropTypes.bool
};

export default SquareButton;

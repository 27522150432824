import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    width: '100%',

    '& h3': {
      color: theme.palette.secondary.main,
      fontWeight: '700',
      margin: 0,
      padding: 0,
    }
  },

  anotherMonth: {
    backgroundColor: theme.palette.grey['100'],
    cursor: 'default',

    '& > *': {
      opacity: 0.2,
    }
  },

  today: {
    '& h3': {
      color: theme.palette.primary.main,
    }
  },
}));

const Day = ({day, onSelectDay, onRenderEvents = () => null}) => {
  const classes = useStyles();
  return (
    <div
      className={[
        classes.container,
        !day.current ? classes.anotherMonth : '',
        day.today ? classes.today : ''
      ].join(' ')}
      onClick={() => day.current ? onSelectDay(day) : null}
    >
      <h3>{day.date.format('D')}</h3>
      {onRenderEvents(day)}
    </div>
  );
};

export default Day;

import React, {useEffect, useState} from 'react';
import {FULLY_MET, NOT_MET, PARTLY_MET} from '../../../../constants';
import ProgressBar from './ProgressBar';

const AuditProgressBar = ({audit, onSelect = () => null}) => {
  const [progress, setProgress] = useState({counts: [], total: 0});

  useEffect(() => {
    let total = 0;
    const counts = [
      {
        title: 'No Rating',
        colour: null,
        count: 0,
      },
      {
        title: 'Not Met',
        colour: NOT_MET,
        count: 0,
      },
      {
        title: 'Partly Met',
        colour: PARTLY_MET,
        count: 0,
      },
      {
        title: 'Fully Met',
        colour: FULLY_MET,
        count: 0,
      },
    ];
    for (let section of audit.sections) {
      for (let question of section.questions) {
        counts[(question.rating > 0 && question.rating <= 3) ? question.rating : 0].count++;
        total++;
      }
    }
    setProgress({total, counts});
  }, [audit]);

  return <ProgressBar {...progress} onSelect={(title) => onSelect(title, audit)}/>;
};

export default AuditProgressBar;

import {styled, Tooltip, useTheme} from '@mui/material';
import React, {Fragment, useEffect, useState} from 'react';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const Bar = styled('div')(({theme}) => ({
  border: `1px solid ${theme.palette.grey['400']}`,
  borderRadius: theme.spacing(2),
  display: 'flex',
  height: theme.spacing(5),
  overflow: 'hidden',
  width: '100%',

  '&.condensed': {
    height: theme.spacing(3),
  }
}));

const Block = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}));

const Summaries = styled('ul')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  listStyle: 'none',
  margin: 0,
  padding: `${theme.spacing(1)} 0`,
  width: '100%',

  '& > li': {
    margin: 0,
    padding: theme.spacing(1),
  },

  '&.condensed': {
    padding: 0,

    '& > li': {
      flex: 1,
      paddingBottom: 0,
      textAlign: 'center',
    },
  }
}));

const ProgressBar = ({counts, total, onSelect = () => null, condensed = false}) => {
  const [blocks, setBlocks] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const processed = [];
    for (let i = 0; i < counts.length; i++) {
      let size = Math.round((counts[i].count / total) * 1000) / 10;
      processed.push({
        title: counts[i].title,
        backgroundColour: counts[i].colour ?? theme.palette.common.white,
        foregroundColour: counts[i].colour ?? theme.palette.grey['600'],
        size: `${isNaN(size) ? 0 : size}%`,
        count: counts[i].count
      });
    }
    setBlocks(processed);
  }, [counts, total, theme]);

  return blocks.length > 0 ? (
    <Container>
      <Bar className={condensed ? 'condensed' : ''}>
        {blocks.map(({backgroundColour, title, size, count}, index) => {
          const block = (
            <Block
              onClick={() => onSelect ? onSelect(title) : null}
              style={{backgroundColor: backgroundColour, flex: `0 0 ${size}`, width: size}}
            />
          );
          return (
            <Fragment key={index}>
              {condensed ? block : (
                <Tooltip title={`${title}: ${size} (${count} / ${total})`}>
                  {block}
                </Tooltip>
              )}
            </Fragment>
          );
        })}
      </Bar>
      <Summaries className={condensed ? 'condensed' : ''}>
        {blocks.map(({foregroundColour, title, size, count}, index) => (
          <li key={index} style={{color: foregroundColour}}>
            {condensed ? '' : `${title}: `}
            <strong>{size} {condensed ? '' : `(${count} / ${total})`}</strong>
          </li>
        ))}
      </Summaries>
    </Container>
  ) : null;
}

export default ProgressBar;

import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const SchoolForm = forwardRef(({trustID = null, school, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={school?.arlo ? {...school, arlo_id: school.arlo.id} : school}
      type="school"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, name, arlo_id, type_id, rating_id}) => services.school.saveSchool({
        id,
        name,
        arlo_id,
        trust_id: trustID,
        type_id,
        rating_id
      })}
      onSaved={onSaved}
    />
  );
});

export default SchoolForm;

import {Box, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Table from '../Audits/Table';
import Summary from './Summary';
import Surface from './Surface';

const Auditor = ({onLoading}) => {
  const services = useContext(ServiceContext);
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    onLoading(true);
    services.audit.getSummary().then((retrievedSummary) => {
      setSummary(retrievedSummary);
        onLoading(false);
    }).catch(() => onLoading(false));
  }, [services, onLoading]);

  return (
    <>
      <Surface>
        <Typography variant="h6">Ongoing Audits</Typography>
        {summary ? <Summary summary={summary}/> : null}
      </Surface>
      <Box marginTop={2}>
        <Table title="My Audits"/>
      </Box>
    </>
  );
}

export default Auditor;

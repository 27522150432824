import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import {CorporateFare, FactCheck, FormatListNumbered, Quiz, School as SchoolIcon} from '@mui/icons-material';
import * as React from 'react';
import routes from '../../../routes';
import AuditsTable from './Audits/Table';
import TypesTable from './AuditTypes/Table';
import QuestionsTable from './Questions/Table';
import SchoolsTable from './Schools/Table';
import TrustsTable from './Trusts/Table';

const TABS = [
  {
    id: 'audits',
    slug: 'audits',
    title: 'Audits',
    icon: <FactCheck/>,
    render: (props) => <AuditsTable {...props} archive={true}/>
  },
  {
    id: 'questions',
    slug: 'questions',
    title: 'Questions',
    icon: <Quiz/>,
    render: (props) => <QuestionsTable {...props} archive={true}/>
  },
  {
    id: 'categories',
    slug: 'categories',
    title: 'Categories',
    icon: <FormatListNumbered/>,
    render: (props) => <TypesTable {...props} archive={true}/>
  },
  {
    id: 'trusts',
    slug: 'trusts',
    title: 'Trusts',
    icon: <CorporateFare/>,
    render: (props) => <TrustsTable {...props} archive={true}/>
  },
  {
    id: 'schools',
    slug: 'schools',
    title: 'Schools',
    icon: <SchoolIcon/>,
    render: (props) => <SchoolsTable {...props} archive={true}/>
  },
];

const Archive = () => {
  const {tab, handleTab} = useTab(TABS, routes.archive.index);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Archive'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}

export default Archive

import {DataTable, date, name} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import AuditStatus from '../../../../components/AuditStatus';
import DetailDialog from '../../../../components/DetailDialog';
import routes from '../../../../routes';
import AuditTableProgressBar from '../Audit/AuditTableProgressBar';

const AuditsDialog = ({title, audits, open, onClose}) => {
  const history = useHistory();

  const handleSelect = useCallback((audit) => {
    onClose();
    history.push(reverse(routes.audits.detail, {id: audit.id}));
  }, [history, onClose]);

  return (
    <DetailDialog
      title={title}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      actions={[
        {label: 'Close', colour: 'primary', onClick: () => onClose()}
      ]}
    >
      <DataTable
        title="Audits"
        options={{
          search: false,
          paging: false
        }}
        canRefresh={false}
        columns={useMemo(() => [
          {title: 'Type', field: 'title', sorting: false},
          {title: 'School', field: 'school', render: item => item.school.name, sorting: false},
          {title: 'Auditor', field: 'user', render: item => name(item.user), sorting: false},
          {title: 'Date', field: 'date', render: item => item.date ? date(item.date) : '-', sorting: false},
          {title: 'Status', field: 'status', sorting: false, render: item => <AuditStatus status={item.status}/>},
          {
            title: 'Progress',
            field: 'progress',
            sorting: false,
            render: item => <AuditTableProgressBar summary={item.progress}/>
          }
        ], [])}
        data={audits}
        onRowClick={handleSelect}
      />
    </DetailDialog>
  );
}

export default AuditsDialog;
